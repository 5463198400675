import { Node, mergeAttributes } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';
import { Column } from './column';
import { ColumnSelection } from './column-selection';
import {
  buildColumn,
  buildThreeColumnBlock,
  buildNColumns,
  findParentNodeClosestToPos,
} from './utils';

export const ThreeColumnBlock = Node.create({
  name: 'threeColumnBlock',
  group: 'block',
  content: 'column{3,}',
  isolating: true,
  selectable: true,

  addOptions() {
    return {
      nestedColumns: false,
      columnType: Column,
    };
  },

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, {
      'data-type': this.name,
      class: 'three-column-block',
    });
    return ['div', attrs, 0];
  },

  addCommands() {
    const unsetThreeLayoutColumns = () => ({ tr, dispatch }) => {
      try {
        if (!dispatch) {
          return;
        }

        const pos = tr.selection.$from;
        const where = ({ node }) => {
          if (!this.options.nestedColumns && node.type === this.type) {
            return true;
          }
          return node.type === this.type;
        };
        const firstAncestor = findParentNodeClosestToPos(pos, where);
        if (firstAncestor === undefined) {
          return;
        }

        let nodes = [];
        firstAncestor.node.descendants((node, _, parent) => {
          if (parent?.type.name === Column.name) {
            nodes.push(node);
          }
        });
        nodes = nodes.reverse().filter((node) => node.content.size > 0);

        const resolvedPos = tr.doc.resolve(firstAncestor.pos);
        const sel = new NodeSelection(resolvedPos);

        tr = tr.setSelection(sel);
        nodes.forEach((node) => {
          tr = tr.insert(firstAncestor.pos, node);
        });
        tr = tr.deleteSelection();
        return dispatch(tr);
      } catch (error) {
        console.error(error);
      }
    };

    const setThreeLayoutColumns = (keepContent = false) => ({ tr, dispatch }) => {
      try {
        const { doc, selection } = tr;
        if (!dispatch) {
          console.log('no dispatch');
          return;
        }

        const sel = new ColumnSelection(selection);
        sel.expandSelection(doc);

        const { openStart, openEnd } = sel.content();
        if (openStart !== openEnd) {
          console.warn('failed depth check');
          return;
        }

        let threeColumnBlock;
        if (keepContent) {
          const content = sel.content().toJSON();
          const firstColumn = buildColumn(content);
          const otherColumns = buildNColumns(2); // For 3 columns total
          threeColumnBlock = buildThreeColumnBlock({
            content: [firstColumn, ...otherColumns],
          });
        } else {
          const columns = buildNColumns(3); // Create 3 columns
          threeColumnBlock = buildThreeColumnBlock({ content: columns });
        }
        const newNode = doc.type.schema.nodeFromJSON(threeColumnBlock);
        if (newNode === null) {
          return;
        }

        const parent = sel.$anchor.parent.type;
        const canAcceptThreeColumnBlockChild = (par) => {
          if (!par.contentMatch.matchType(this.type)) {
            return false;
          }

          if (!this.options.nestedColumns && par.name === Column.name) {
            return false;
          }

          return true;
        };
        if (!canAcceptThreeColumnBlockChild(parent)) {
          console.warn('content not allowed');
          return;
        }

        tr = tr.setSelection(sel);
        tr = tr.replaceSelectionWith(newNode, false);
        return dispatch(tr);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      unsetThreeLayoutColumns,
      setThreeLayoutColumns,
    };
  },
});
