const originalPositions = new Map();

export default {
    methods: {
        toggleFullscreen(event) {
            const isFullScreen = event.currentTarget.closest('.is-full-screen');

            if (isFullScreen) {
                const isFullscreen = isFullScreen.classList.toggle('fullscreen');
                document.body.classList.toggle('no-scroll', isFullscreen);
              
                const proseMirror = isFullScreen.querySelector('.ProseMirror');
                const toolbarSticky = isFullScreen.querySelector('.toolbar-sticky');
                const scrollToTopBtn = document.querySelector('.scroll-to-top-button');
                // const isFullScreenToggleBtn = document.getElementById('FullscreenToggle')

                if (isFullscreen) {
                    isFullScreen.classList.add('card')
                    // isFullScreenToggleBtn.classList.add('toggle-btn-active');
                    // Store the original parent and next sibling in the Map
                    originalPositions.set(isFullScreen, {
                        parent: isFullScreen.parentNode,
                        nextSibling: isFullScreen.nextElementSibling
                    });
                    
                    document.body.appendChild(isFullScreen);
                    
                    if (proseMirror) {
                        proseMirror.style.minHeight = '0';
                        proseMirror.style.height = '80vh';
                    }
                    if (toolbarSticky) {
                        toolbarSticky.style.position = 'relative';
                        toolbarSticky.style.top = '0';
                    }
                    if(scrollToTopBtn) {
                        scrollToTopBtn.style.visibility = 'hidden';
                    }
                } else {
                    isFullScreen.classList.remove('card')
                    // isFullScreenToggleBtn.classList.remove('toggle-btn-active');
                    // Restore the original parent and next sibling from the Map
                    const originalPosition = originalPositions.get(isFullScreen);
                    if (originalPosition && originalPosition.parent) {
                        originalPosition.parent.insertBefore(isFullScreen, originalPosition.nextSibling);
                    }
      
                    if (proseMirror) {
                        proseMirror.style.minHeight = '110px';
                        proseMirror.style.height = 'auto';
                    }
                    if (toolbarSticky) {
                        toolbarSticky.style.position = 'sticky';
                        toolbarSticky.style.top = '60px';
                    }
                    if(scrollToTopBtn) {
                        scrollToTopBtn.style.visibility = 'visible';
                    }
                    // Remove the stored position after restoring
                    originalPositions.delete(isFullScreen);
                }
            }
        }
    }
};