import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/cryptos/brokers/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/cryptos/brokers`, data)
  },
  update(data) {
    return Api().put(`hercules/cryptos/brokers/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/cryptos/brokers/${row.id}`)
  }
}