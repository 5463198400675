import SportsTeams from '@pages/sports/teams/teams.vue'
import SportsTeamsEdit from '@pages/sports/teams/teams-edit.vue'
import SportsTeamsHome from '@pages/sports/teams/teams-home.vue'
import SportsTeamsMarketData from '@pages/sports/teams/teams-market-data.vue'
import SportsTeamsTranslations from '@pages/sports/teams/teams-translations.vue'
import SportsTournaments from '@pages/sports/tournaments.vue'
import SportsEvents from '@pages/sports/events.vue'
import SportsEventMapper from '@pages/sports/event-stream.vue'
import SportsProviders from '@pages/sports/providers/providers.vue'
import SiteTournaments from '@pages/sports/site-tournaments.vue'
import SiteProviders from '@pages/sports/site-providers/site-providers.vue'
import ProviderHome from '@pages/sports/providers/providers-home.vue'
import ProviderEdit from '@pages/sports/providers/provider-edit.vue'
import SiteProviderEdit from '@pages/sports/site-providers/site-provider-edit.vue'
import SiteSportProviders from '@pages/sports/site-providers/site-sport-providers.vue'
import SiteProviderLinks from '@pages/sports/site-providers/site-provider-links.vue'
import SiteSports from '@pages/sports/site-sports.vue'
import SiteCountries from '@pages/sports/site-countries.vue'
import SiteEvents from '@pages/sports/site-events.vue'

export default [
  {
    path: '/sports-teams',
    component: SportsTeams,
    name: 'Teams',
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Teams',
      permission: 'view.sports/teams',
    },
  },
  {
    path: '/sports-teams/:id',
    component: SportsTeamsHome,
    children: [
      {
        path: '',
        component: SportsTeamsEdit,
        meta: {
          breadCrumb: 'Teams',
          auth: true,
          permission: 'view.sports/teams',
          name: 'General',
          parent: 'Sports',
        },
      },
      {
        path: 'market-data',
        component: SportsTeamsMarketData,
        meta: { breadCrumb: 'Teams', parent: 'Sports', name: 'Market data', permission: 'view.sports/teams' },
      },
      {
        path: 'translations',
        component: SportsTeamsTranslations,
        meta: {
          breadCrumb: 'Teams',
          parent: 'Sports',
          name: 'Translations',
          permission: 'view.sports/teams',
        },
      },
    ],
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Teams',
    },
  },
  {
    path: '/sports-tournaments',
    component: SportsTournaments,
    name: 'Tournaments',
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Tournaments',
      permission: 'view.sports/tournaments',
    },
  },
  {
    path: '/sports-events',
    component: SportsEvents,
    name: 'Events',
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Events',
      permission: 'view.sports/events',
    },
  },
  {
    path: '/sports-event-mapper',
    component: SportsEventMapper,
    name: 'Event Mapper',
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Event Mapper',
      permission: 'view.sports/event-streams',
    },
  },
  {
    path: '/sports-site-providers/:id',
    component: ProviderHome,
    children: [
      {
        path: 'general',
        component: ProviderEdit,
        meta: {
          parent: 'Sports',
          breadCrumb: 'Site Providers',
          auth: true,
          permission: 'view.sports/site-providers',
          name: 'General',
        },
      },
      {
        path: 'site-data',
        component: SiteProviderEdit,
        name: 'Sites data',
        meta: {
          breadCrumb: 'Site Providers',
          parent: 'Sports',
          name: 'Sites data',
          permission: 'view.sports/site-providers',
        },
      },
      {
        path: 'sports-data',
        component: SiteSportProviders,
        meta: {
          breadCrumb: 'Site Providers',
          parent: 'Sports',
          name: 'Site sports data',
          permission: 'view.sports/site-providers',
        },
      },
      {
        path: 'links',
        component: SiteProviderLinks,
        meta: {
          breadCrumb: 'Site Providers',
          parent: 'Sports',
          name: 'Links',
          ermission: 'view.sports/site-providers',
        },
      },
    ],
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Site Providers',
    },
  },
  {
    path: '/sports-providers',
    component: SportsProviders,
    name: 'Providers',
    meta: {
      auth: true,
      parent: 'Sports',
      name: 'Providers',
      permission: 'view.sports/providers',
    },
  },
  {
    path: '/sports-sports',
    component: SiteSports,
    meta: { parent: 'Sports', name: 'Site Sports', permission: 'view.sports/sports' },
  },
  {
    path: '/sports-countries',
    component: SiteCountries,
    meta: { parent: 'Sports', name: 'Site Countries', permission: 'view.sports/countries' },
  },
  {
    path: '/sports-site-events',
    name: 'Site Events',
    component: SiteEvents,
    meta: { parent: 'Sports', name: 'Site Events', permission: 'view.sports/site-events' },
  },
  {
    path: '/sports-site-countries',
    component: SiteCountries,
    name: 'Site Countries',
    meta: { parent: 'Sports', name: 'Site Countries', permission: 'view.sports/site-countries' },
  },
  {
    path: '/sports-site-sports',
    component: SiteSports,
    name: 'Site Sports',
    meta: { parent: 'Sports', name: 'Site Sports', permission: 'view.sports/site-sports' },
  },
  {
    path: '/sports-site-tournaments',
    component: SiteTournaments,
    name: 'Site Tournaments',
    meta: { parent: 'Sports', name: 'Site Tournaments', permission: 'view.sports/tournaments' },
  },
  {
    path: '/sports-site-providers',
    component: SiteProviders,
    name: 'Site Providers',
    meta: { parent: 'Sports', name: 'Site Providers', permission: 'view.sports/site-providers' },
  },
]
