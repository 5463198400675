export default {
    data() {
      return {
        defaultOperatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
        operatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
        platformPlaceholder: 'Filter',
        platformDisabled: false,
        platformClearable: true,
        operatorPlaceholder: 'Filter',
        operatorDisabled: false,
        operatorClearable: true,
        unitPlaceholder: 'Filter',
        unitDisabled: false,
        unitClearable: true,
        platforms: null,
        operators: null,
        units: null,
      };
    },
    watch: {
      platforms(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearOperators();
          if (newValue) {
            this.operatorPath = `koala/v1/operators?orderBy=name&ascending=1&limit=100&platforms=${newValue}`;
          } else {
            this.operatorPath = this.defaultOperatorPath;
          }
          this.fetchDashboardData();
        }
      },
      operators(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.operatorChanged();
            if (!newValue) {
            this.operatorPath = this.defaultOperatorPath;
            }
        }
      },
      units(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.unitChanged();
        }
      },
    },
    computed: {
      platformsColumn() {
        return {
          label: 'Platform',
          field: 'platforms',
          type: 'text',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          order_key: 'operator.platform.name',
          fetch_on_load: false,
          trackBy: 'id',
          searchBy: 'name'
        }
      },
      operatorsColumn() {
        return {
          label: 'Operators',
          field: 'operators',
          type: 'text',
          search_key: 'operators',
          advancedFilter: true,
          path: this.operatorPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          fetch_on_load: false,
          trackBy: 'id',
          searchBy: 'name'
        }
      },
      unitsColumn() {
        return {
          label: 'Units',
          field: 'business_units',
          type: 'text',
          search_key: 'unit',
          advancedFilter: true,
          path: 'koala/v1/business-units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          fetch_on_load: false,
          trackBy: 'id',
          searchBy: 'name'
        }
      },
    },
    methods: {
      advancedFilter(filter) {
        if (filter.platforms !== undefined) {
          if (filter.platforms) {
            this.platforms = filter.platforms;
            this.platformPlaceholder = "Advanced Filter On";
            this.platformDisabled = true;
            this.platformClearable = false;
            this.operatorPath = `koala/v1/operators?orderBy=name&ascending=1&limit=100&platforms=${filter.platforms}`;
          } else {
            this.platforms = null;
            this.platformPlaceholder = "Filter";
            this.platformDisabled = false;
            this.platformClearable = true;
            this.operatorPath = this.defaultOperatorPath;
          }
        }
  
        if (filter.operators !== undefined) {
          if (filter.operators) {
            this.operators = filter.operators;
            this.operatorPlaceholder = "Advanced Filter On";
            this.operatorDisabled = true;
            this.operatorClearable = false;
          } else {
            this.operators = null;
            this.operatorPlaceholder = "Filter";
            this.operatorDisabled = false;
            this.operatorClearable = true;
          }
        }
  
        if (filter.business_units !== undefined) {
          if (filter.business_units) {
            this.units = filter.business_units;
            this.unitPlaceholder = "Advanced Filter On";
            this.unitDisabled = true;
            this.unitClearable = false;
          } else {
            this.units = null;
            this.unitPlaceholder = "Filter";
            this.unitDisabled = false;
            this.unitClearable = true;
          }
        }
      },
      clearOperators() {
        this.operators = null;
        this.$refs.operatorsRef.options = [];
        this.$refs.operatorsRef.activeOptionLabel = "";
        this.$refs.operatorsRef.data = "";
      },
    },
  };
  