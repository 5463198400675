<template>
    <data-table
      model="GameSymbol"
      service-file="GameSymbolsService"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
    />
  </template>
  
  <script>
  import TableColumnTemplate from '@constants/table-column-template'
  import BasicOptions from '@mixins/BasicOptions'
  
  export default {
    created() {
      this.setTitle(
        `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
      )
    },
    data() {
      return {
        game_id: this.$route.params['id'],
      }
    },
    methods: {
      columns() {
        return [
          {
            field: 'image.url',
            type: 'text',
            filterable: false,
            label: 'Preview',
            template: TableColumnTemplate.IMAGE,
          },
          { field: 'description', type: 'text', filterable: true },
          { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        ]
      },
      path() {
        return `hercules/games/game-symbols?game_id=${this.$route.params['id']}&with=game`
      },
      createDefaults() {
        return {
          game_id: this.$route.params['id'],
        }
      },
    },
  }
  </script>
  
  <style scoped></style>
  