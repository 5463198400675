<template>
  <div :class="wrapperClass" :name="label && slugify(label)">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">*</span>
    <div class="d-inline ml-2" v-if="clearable && currentValue && currentValue.length > 0">
      <a class="text-muted" @click="clear">clear</a>
    </div>
    <div class="popover-wrapper date-picker clickable">
      <flat-pickr
        v-bind="$attrs"
        class="form-control"
        v-model="currentValue"
        v-bind:disabled="disabled || (readOnly && formType === 'edit')"
      />
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'

export default {
  components: { FlatPickr },
  computed: {
    currentValue: {
      get() {
        return this.modelValue || this.defaultDate || ''
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  watch: {
    modelValue(newVal) {
      this.$nextTick(() => {
        this.currentValue = newVal || this.defaultDate || ''
      })
    },
  },
  props: {
    wrapperClass: {
      default: 'form-group',
    },
    helpText: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      default: false,
    },
    formType: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      default: true,
    },
    defaultDate: {
      default: '',
    },
  },
  methods: {
    clear() {
      if (!this.disabled) {
        this.currentValue = ''
      }
    },
  },
}
</script>
