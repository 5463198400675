<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <div class="border py-3">
      <div class="border border-light mx-3 mb-3" v-for="(record, key) in localRecords" :key="key">
        <div class="m-3">
          <div class="text-muted">{{ label }} entry #{{ key }}</div>
          <div class="d-flex" style="gap: 16px">
            <base-input v-model="record.type" helpText="Specify type" />
            <base-input v-model="record.name" helpText="Specify name" />
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex align-items-center" v-for="(link, linkKey) in record.links" :key="linkKey">
                <base-input class="flex-grow-1" v-model="link.link" helpText="Link for related topic" />
                <a class="ml-4 text-center mr-3" @click="deleteLinkRow(key, linkKey)">
                  <i class="uil uil-trash-alt mb-3"></i>
                </a>
              </div>
              <a class="btn btn-sm btn-info" @click="addSameAs(key)">
                Add another link<i class="uil uil-plus ml-2"></i>
              </a>
            </div>
          </div>
          <a class="btn btn-secondary btn-sm h-50 align-self-start mt-4" @click="deleteRow(key)">
            <i class="uil uil-trash-alt mr-2"></i>Delete entry #{{ key }}
          </a>
        </div>
      </div>
      <div class="d-flex">
        <a class="btn btn-primary btn-sm ml-3" @click="addRow">
          <i class="uil uil-plus-alt mr-2"></i>+ Add {{ localRecords.length === 0 ? 'a' : 'another' }} knows
          about entry
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseInput },
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localRecords: cloneDeep(this.modelValue),
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.localRecords = cloneDeep(newVal)
      },
      deep: true,
      immediate: true,
    },
    localRecords: {
      handler(newVal) {
        if (!isEqual(newVal, this.modelValue)) {
          this.$emit('update:modelValue', newVal)
        }
      },
      deep: true,
    },
  },
  methods: {
    deleteLinkRow(knowsAboutKey, linkKey) {
      if (this.localRecords[knowsAboutKey].links.length === 1) {
        this.localRecords[knowsAboutKey].links[linkKey].link = null
      } else {
        this.localRecords[knowsAboutKey].links = this.localRecords[knowsAboutKey].links.filter(
          (_link, key) => key !== linkKey
        )
      }
    },
    deleteRow(index) {
      this.localRecords = this.localRecords.filter((_record, key) => key !== index)
    },
    addRow() {
      this.localRecords.push({
        id: 0,
        type: null,
        name: null,
        links: [{ link: null }],
      })
    },
    addSameAs(key) {
      this.localRecords[key].links.push({
        link: null,
      })
    },
  },
}
</script>

<style></style>
