<template>
  <div v-if="cryptoBroker">
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/crypto-brokers` }"
            data-tooltip="Back to crypto brokers"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ cryptoBroker.name }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(cryptoBroker.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/crypto-brokers/${cryptoBroker.id}` }" exact>
        <a>General</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import CryptoBrokersService from '@services/CryptoBrokersService'

export default {
  data() {
    return {
      cryptoBroker: null,
      title: '',
    }
  },
  mounted() {
    this.getCryptoBroker()
  },
  methods: {
    getCryptoBroker() {
      CryptoBrokersService.getOne(this.$route.params['id'], {})
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Broker not found') >= 0) {
                this.$router.push('/crypto-brokers')
              }
            })
          } else {
            this.cryptoBroker = response.data.result[0]
            this.title = response.data.result[0].name
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
