import { Extension } from '@tiptap/core'
import { Column } from './column'
// import { ColumnIcon } from '../panel-blocks/column-icon'
import { ColumnBlock } from './column-block'
import { ThreeColumnBlock } from './three-column-block'
import { PanelBlock } from '../panel-blocks/panel-block'

export const ColumnsExtension = Extension.create({
  name: 'columnsExtension',

  addExtensions() {
    const extensions = []

    if (this.options.column !== false) {
      extensions.push(Column)
    }

    // if (this.options.columnIcon !== false) {
    //   extensions.push(ColumnIcon)
    // }

    if (this.options.columnBlock !== false) {
      extensions.push(ColumnBlock)
    }

    if (this.options.threeColumnBlock !== false) {
      extensions.push(ThreeColumnBlock)
    }

    if (this.options.panelBlock !== false) {
      extensions.push(PanelBlock)
    }

    return extensions
  },
})

export { Column, ColumnBlock, ThreeColumnBlock, PanelBlock }
export default ColumnsExtension
