import { createApp } from 'vue'
import { PromiseDialog } from 'vue3-promise-dialog'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

import App from './App.vue'
import { router } from './router'
import http from './http'
import config from './config'
import AuthHelper from './mixins/AuthHelper'
import BadgeHelper from './mixins/BadgeHelper'
import BaseFieldHelper from './mixins/BaseFieldHelper'

import DynamicForm from './components/fields/DynamicForm'
import DynamicFields from './components/fields/DynamicFields'
import ClickOutsideDirective from './directives/click-outside'
import ClickAway from './directives/click-away'
import CustomFilters from './filters/CustomFilters'
import GeneralHelper from './mixins/GeneralHelper'
import EliasHelper from './mixins/EliasHelper'
import MathHelper from './mixins/MathHelper'
import ModuleHelper from './mixins/ModuleHelper'
import NotificationHelper from './mixins/NotificationHelper'
import SiteHelper from './mixins/SiteHelper'
import { createGtm } from 'vue-gtm'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto'
import { store } from './store/index'
import vueDebounce from 'vue-debounce'
import VueDraggableResizable from 'vue-draggable-resizable'
import PortalVue from 'portal-vue'
import Pusher from 'pusher-js'
import VirtualList from 'vue3-virtual-scroll-list'
import Popper from 'vue3-popper'
import auth from './config/plugins'
import DataTable from '@templates/table/table.vue'
import mitt from 'mitt'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import VueApexCharts from 'vue3-apexcharts'
import JsonViewer from 'vue-json-viewer'

const app = createApp(App)
app.use(VueAxios, axios)

app.config.globalProperties.axios = axios

const emitter = mitt()
if (process.env.VUE_APP_SENTRY_DSN && process.env.NODE_ENV !== 'local') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new BrowserTracing({
        ignoreErrors: ['ChunkLoadError'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.05,
  })
}

window.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, { cluster: process.env.VUE_APP_PUSHER_CLUSTER })

app.directive('debounce', vueDebounce({ lock: true }))

app.use(PromiseDialog)
app.use(VueCookies)
app.use(PortalVue)
app.use(VueScrollTo)

app.use(JsonViewer)
app.mixin(NotificationHelper)
app.mixin(SiteHelper)
app.mixin(AuthHelper)
app.mixin(GeneralHelper)
app.mixin(EliasHelper)
app.mixin(MathHelper)
app.mixin(ModuleHelper)
app.mixin(BaseFieldHelper)
app.mixin(BadgeHelper)

app.directive('click-outside', ClickOutsideDirective)
app.directive('click-away', ClickAway)

Object.entries(CustomFilters).forEach(([name, method]) => {
  app.config.globalProperties[`$${name}`] = method
})
app.config.productionTip = false

app.component('data-table', DataTable)
app.component('dynamic-form', DynamicForm)
app.component('dynamic-fields', DynamicFields)
app.component('vue-draggable-resizable', VueDraggableResizable)
app.component('virtual-list', VirtualList)
app.component('Popper', Popper)
app.component('VueDatePicker', VueDatePicker)
app.component('apexchart', VueApexCharts)

if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  app.use(
    createGtm({
      id: 'GTM-W6NV8BV',
      enabled: true,
      debug: true,
      loadScript: true,
      vueRouter: router,
    })
  )
}

// app.config.globalProperties.$http = http
app.config.devtools = process.env.VUE_APP_ENV != 'production'
app.config.globalProperties.emitter = emitter

Object.keys(NotificationHelper).forEach((key) => {
  app.config.globalProperties[key] = NotificationHelper[key]
})
Object.keys(AuthHelper).forEach((key) => {
  app.config.globalProperties[key] = AuthHelper[key]
})
app.use(http).use(config).use(store).use(auth).use(router).mount('#app')

router.beforeResolve((to, from, next) => {
  if (to.meta.auth === false) {
    return next()
  }

  const settings =
    app.config.globalProperties.$auth.user() && app.config.globalProperties.$auth.user().settings
  const maintenanceEnabled = settings.maintenance_mode_enabled == 1

  if (to.path !== '/maintenance' && maintenanceEnabled && app.hasRole('admin')) {
    router.push('/maintenance')
    return
  }

  if (to.meta.permission && !app.config.globalProperties.hasPermission(to.meta.permission)) {
    app.config.globalProperties.showErrorMessage('Not allowed to view this page')
    router.push('/')
    return
  }

  next()
})

document.addEventListener('click', function (event) {
  if (event.target.matches('button')) {
    event.target.focus()
  }
})

export default app
