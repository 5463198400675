<template>
  <div :class="[defaultInputClass ? 'form-group' : '', customClass]">
    <label v-if="showLabel">{{ $prettyLabels(label) }}</label>

    <label class="text-muted ml-1 clickable" v-if="labelAction" @click="labelAction">
      {{ labelActionTitle }}
    </label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <input
      v-bind="$attrs"
      :name="label && slugify(label)"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown="preventInvalidKeys"
      class="form-control"
      v-bind:class="{
        'text-muted': modelValue === placeholder || !modelValue,
        loading: loading,
      }"
      :placeholder="placeholder"
      type="number"
      ref="input"
      :required="required"
      :readonly="readOnly"
      :disabled="readOnly"
    />

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    defaultInputClass: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      default: () => false,
    },
    placeholder: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    helpText: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    autoFocus: {
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    field: {
      required: false,
      default: () => null,
    },
    customClass: {
      required: false,
      default: () => '',
    },
    autoFocusDelay: {
      default: 300,
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.setFocused()
    }
  },
  methods: {
    setFocused() {
      setTimeout(() => {
        if (this.$refs && Object.hasOwn(this.$refs, 'input')) {
          this.$nextTick(this.$refs.input.focus())
        }
      }, this.autoFocusDelay)
    },
    preventInvalidKeys(event) {
      if (event.keyCode === 69 || event.keyCode === 189) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style scoped>
input[type='number'].noArrows::-webkit-inner-spin-button,
input[type='number'].noArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
