<template>
  <div class="">
    <sidebar-filter ref="sidebar" :fromSchema="false" />
    <breadcrumb-edit ref="edit" />
  </div>
</template>

<script>
import SidebarFilter from '@molecules/cms/sites/sidebar-filter.vue'
import BreadcrumbEdit from '@organisms/cms/sites/breadcrumb/edit.vue'
import _ from 'lodash'

export default {
  beforeRouteLeave(to, from) {
    if (this.$refs.edit && !_.isEqual(this.$refs.edit?.initBreadcrumbs, this.$refs.edit?.breadcrumbs)) {
      if (!confirm('You have unsaved changes. Please save or cancel the changes before closing the form.')) {
        return false
      }
    }
  },
  created() {
    this.setTitle()
  },
  data() {
    return {
      showBulkEdit: false,
    }
  },
  components: { SidebarFilter, BreadcrumbEdit },
}
</script>
