<template>
  <div class="form-group" style="margin-bottom: 28px">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <codemirror
      id="myCodemirror"
      ref="myCm"
      v-model="value"
      :extensions="extensions"
      :autofocus="true"
      :tabSize="4"
      :indent-with-tab="true"
      :language="this.language"
      :lineWrapping="true"
      :disabled="this.readOnly"
      @update:modelValue="updateValue"
      :width="'200px'"
    ></codemirror>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import { Codemirror } from 'vue-codemirror'
import { html } from '@codemirror/lang-html'
import { EditorView } from '@codemirror/view'

export default {
  components: { Codemirror },
  data() {
    return {
      extensions: [html(), EditorView.lineWrapping],
      initialValue: '',
      value: this.code,
    }
  },
  watch: {
    code(newCode) {
      this.value = newCode
    },
  },
  props: {
    label: {
      type: [String],
      default: () => null,
    },
    helpText: {
      type: String,
      default: () => null,
    },
    language: {
      type: String,
      default: () => 'javascript',
    },
    code: {
      type: String,
      default: () => '',
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    heightAuto: {
      type: Boolean,
      default: () => false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value, this.index)
    },
  },
}
</script>
<style scoped>
.code-overflow .cm-scroller {
  overflow-x: hidden;
}
</style>
