<template>
  <div class="form-group is-full-screen" style="margin-bottom: 28px">
    <label class="mb-2" v-if="label">{{ $prettyLabels(label) }}</label>
    <div class="border rounded-top" :class="{ 'border-bottom-0': !showSource, rounded: showSource }">
      <toolbar
        ref="toolbar"
        class="toolbar-sticky border-bottom"
        :editor="editor"
        :showSource="showSource"
        @toggleSource="toggleSource"
        @setLink="() => $refs.modals.setLink()"
        @setAnchor="() => $refs.modals.setAnchor()"
        @setImage="() => $refs.modals.setImage()"
        :toggleDisableTableButton="toggleDisableTableButton"
        :isMac="isMac"
        :hiddenTools="hiddenTools"
      />
      <bubble-menus
        v-show="editor && !showSource"
        :editor="editor"
        @setLink="() => $refs.modals.setLink()"
        @setAnchor="() => $refs.modals.setAnchor()"
        @setImage="$refs.modals.setImage()"
        @setAltText="$refs.modals.setAltText()"
        @setTitle="$refs.modals.setTitle()"
        :isTable="toggleDisableTableButton"
        :trackingLink="trackingLink"
        :showSource="showSource"
      />
      <modals
        ref="modals"
        :marketId="marketId"
        :siteId="siteId"
        :editor="editor"
        :trackingLink="trackingLink"
      />
      <div @keydown="handleKeydown">
        <editor-content v-if="!showSource" :editor="editor" ref="editorContent" />
        <base-code-text
          v-else
          :code="htmlContent"
          language="html"
          :readOnly="false"
          :heightAuto="true"
          class="mb-0 code-min-height"
          @change="(value) => (htmlContent = value)"
        />
      </div>
    </div>
    <div
      class="d-flex justify-content-end align-items-center border rounded-bottom py-1 px-2"
      v-show="isLoaded && !showSource"
    >
      <div class="d-flex justify-content-end text-muted mr-2">Characters : {{ charactersCount }}</div>
      <div class="d-flex justify-content-end text-muted">Words : {{ words }}</div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Table from '@tiptap/extension-table'
import TableHeader from './customTableHeader.js'
import TableCell from './customTableCell.js'
import TableRow from '@tiptap/extension-table-row'
import Link from './customLink'
import Image from './customImage'
// import Image from '@tiptap/extension-image'
// import ImageLink from './imageLink'
import Gapcursor from '@tiptap/extension-gapcursor'
import Figure from './customFigure'
import Heading from './customHeading'
import Toolbar from './base-rich-text-toolbar.vue'
import BubbleMenus from './base-rich-text-bubble-menus.vue'
import Modals from './base-rich-text-modals.vue'
import TextAlign from '@tiptap/extension-text-align'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import Strike from '@tiptap/extension-strike'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
// import CustomBulletList from './customBulletList'
// import CustomOrderedList from './customOrderedList'
import ListItem from '@tiptap/extension-list-item'
// import ListItem from './customListItem'
import Code from '@tiptap/extension-code'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Blockquote from '@tiptap/extension-blockquote'
import CustomParagraph from './customParagraph'
import TextDirection from './customTextDirection'
import BaseCodeText from '@atoms/fields/base-code-text.vue'
import CharacterCount from '@tiptap/extension-character-count'
import { ColumnsExtension } from '../fields/multi-columns/columns.js'
const format = require('pretty')

export default {
  components: {
    Editor,
    EditorContent,
    Toolbar,
    BubbleMenu,
    BubbleMenus,
    Modals,
    BaseCodeText,
  },
  data() {
    return {
      editor: null,
      showSource: false,
      toggleDisableTableButton: false,
      htmlContent: '',
      isLoaded: false,
      isFullScreen: false,
      isMac: navigator.userAgent.indexOf('Mac') != -1,
    }
  },
  mounted() {
    this.isLoaded = false
    const self = this
    this.editor = new Editor({
      content: this.modelValue,
      autofocus: false,
      extensions: [
        // tiptap bug, need to add this so the characterCount works on multiple instances of editor
        CharacterCount.configure(),
        CustomParagraph,
        Document,
        ColumnsExtension,
        StarterKit.configure({
          heading: false,
        }),
        Underline,
        BubbleMenu,
        Image.configure({
          inline: true,
        }).extend({
          addKeyboardShortcuts() {
            return {
              'Mod-Alt-a': () => {
                self.$refs.modals.setImage()
              },
            }
          },
        }),
        Heading.configure({
          levels: [1, 2, 3, 4, 5, 6],
        }),
        Link.extend({
          inclusive: false,
          target: null,
          addKeyboardShortcuts() {
            return {
              'Mod-k': () => {
                self.$refs.modals.setLink()
              },
              'Mod-Shift-\\': () => {
                this.editor
                  .chain()
                  .focus()
                  .unsetUnderline()
                  .unsetStrike()
                  .unsetSuperscript()
                  .unsetSubscript()
                  .unsetCode()
                  .unsetItalic()
                  .unsetBold()
                  .run()
              },
            }
          },
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Gapcursor,
        Table.configure({
          resizable: true,
          allowGapCursor: true,
        }),
        TableRow.extend({
          allowGapCursor: false,
        }),
        TableHeader,
        TableCell,
        Figure.extend({
          inclusive: false,
        }),
        Color,
        TextStyle,
        Highlight.configure({ multicolor: true }),
        Strike.configure({ HTMLAttributes: { style: 'text-decoration: line-through;' } }),
        Superscript.extend({
          addKeyboardShortcuts() {
            return {
              'Mod-Shift-.': () => {
                this.editor.chain().focus().toggleSuperscript().run()
              },
            }
          },
        }),
        Subscript.extend({
          addKeyboardShortcuts() {
            return {
              'Mod-Shift-,': () => {
                this.editor.chain().focus().toggleSubscript().run()
              },
            }
          },
        }),
        BulletList,
        OrderedList,
        ListItem,
        Code,
        HorizontalRule,
        Blockquote,
        TextDirection.configure({
          types: [
            'heading',
            'paragraph',
            'figure',
            'orderedList',
            'bulletList',
            'image',
            'table',
            'tableRow',
            'tableBody',
            'tableCell',
            'tableHeader',
          ],
        }),
      ],
      onUpdate: () => {
        let newValue = this.editor.getHTML()
        let isPtagEmpty = '<p></p>'
        if (newValue === isPtagEmpty) newValue = ''
        this.htmlContent = newValue
        this.$emit('valueChanged', newValue)
        this.$emit('update:modelValue', newValue)
      },

      onSelectionUpdate: ({ editor }) => {
        const { view, state } = editor
        const { from, to } = view.state.selection
        const text = state.doc.textBetween(from, to, '')

        this.$store.commit('tiptap/setSelectedText', text)
        this.$store.commit('tiptap/setIsImageSelected', editor.isActive('image') || editor.isActive('figure'))

        const selectedCells = [...document.querySelectorAll('.selectedCell')]

        const allTH =
          selectedCells.length > 0 &&
          selectedCells.every((element) => {
            if (element && element.nodeName) {
              return element.nodeName === 'TH'
            }
            return false
          })

        const allTD =
          selectedCells.length > 0 &&
          selectedCells.every((element) => {
            if (element && element.nodeName) {
              return element.nodeName === 'TD'
            }
            return false
          })

        this.$store.commit('tiptap/setIsTableSelected', allTH || allTD)

        this.$store.commit('tiptap/setDisableMerge', !allTH && !allTD)

        // this is how i check if we clicked in a table cell or header
        this.toggleDisableTableButton =
          this.editor.isActive('tableCell') || this.editor.isActive('tableHeader')

        if (this.toggleDisableTableButton) {
          this.$store.commit('tiptap/setSelectedText', '')
        }
      },
      editorProps: {
        handlePaste: (view, event, slice) => {
          if (event.target.nodeName === 'FIGCAPTION' && slice.content.content[0].type.name === 'image') {
            return true
          }
          let contentIsList =
            slice?.content?.content[0].type.name === 'bulletList' ||
            slice?.content?.content[0].type.name === 'orderedList'

          if (this.editor.isActive('table') && contentIsList) {
            const newText = slice.content.content.map((node) => node.textContent).join(' ')
            const textNode = view.state.schema.text(newText)

            const transaction = view.state.tr.replaceSelectionWith(textNode, false)

            view.dispatch(transaction)

            return true
          }
          let isPanelBlock = slice?.content?.content?.some((node) => node.type.name === 'panelBlock')

          // If it's a panel block, extract only the text content
          if (isPanelBlock) {
            const textContent = slice.content.content.map((node) => node.textContent).join('\n')

            // Create a new text node from the extracted text
            const textNode = view.state.schema.text(textContent)

            // Replace the current selection with the new text node
            const transaction = view.state.tr.replaceSelectionWith(textNode, false)
            view.dispatch(transaction)

            return true
          }

          const { from, to } = view.state.selection
          this.unsetColorOfPastedText(from, to, slice.content.size)

          return false
        },
      },
    })

    this.htmlContent = format(this.editor.getHTML())
    if (this.htmlContent === '<p></p>') {
      this.htmlContent = ''
    }
    this.setContentFn(this.htmlContent)
    this.isLoaded = true
  },

  methods: {
    setContentFn(content) {
      this.editor.commands.setContent(content)
      this.$emit('valueChanged', content)
      this.$emit('update:modelValue', content)
    },
    handleKeydown(e) {
      let modKey = this.isMac ? e.metaKey : e.ctrlKey

      if (
        (modKey && e.keyCode === 75) ||
        (modKey && e.altKey && e.keyCode === 76) ||
        (modKey && e.altKey && e.keyCode === 82)
      ) {
        e.preventDefault()
      }
    },
    toggleSource() {
      this.showSource = !this.showSource

      if (this.showSource) {
        this.htmlContent = format(this.editor.getHTML())
      } else {
        this.editor.chain().focus().setTextSelection(0).run()
      }

      this.setContentFn(this.htmlContent)
    },
    unsetColorOfPastedText(from, to, size) {
      setTimeout(() => {
        this.editor
          .chain()
          .focus()
          .selectAll()
          .unsetColor()
          .resetAttributes('paragraph', ['style'])
          .resetAttributes('heading', ['style'])
          .setTextSelection(to + size)
          .run()
      }, 100)
    },
  },
  computed: {
    words() {
      return this.editor && this.editor.storage && this.editor.storage.characterCount.words()
    },
    charactersCount() {
      return this.editor && this.editor.storage && this.editor.storage.characterCount.characters()
    },
    content: {
      get: function () {
        return this.modelValue
      },
      set: function (newValue) {
        this.$emit('valueChanged', newValue)
        this.$emit('update:modelValue', newValue)
        this.htmlContent = newValue
      },
    },
    showButton() {
      return this.editor?.state?.selection?.node?.type?.name === 'image'
    },
    isDraggable() {
      return this.editor?.state?.selection?.node?.attrs?.isDraggable
    },
  },
  props: {
    trackingLink: false,
    hiddenTools: {
      type: Array,
      default: () => [],
    },
    toolbarInline: {
      default: false,
    },
    label: {
      type: [String],
      default: () => null,
    },
    helpText: {
      type: String,
    },
    height: {
      default: 300,
    },
    modelValue: {
      type: [String, Number],
      default: () => '',
    },
    type: {
      default: () => 'text',
    },
    autoFocus: {
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    loading: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    field: {
      required: false,
      default: () => null,
    },
    showWordCount: {
      default: () => true,
      type: Boolean,
    },
    siteId: {
      default: () => null,
      type: Number,
    },
    marketId: {
      default: () => null,
      type: Number,
    },
  },
}
</script>

<style lang="scss">
.ProseMirror {
  min-height: 100px;
  padding: 10px;
  border: 1px solid #efefef;
  border-bottom: none;
  border-top: none;
  border-left-color: white;
  border-right-color: white;
  overflow: auto;

  > * + * {
    margin-top: 0.75em;
  }

  code {
    font-size: 0.9rem;
    padding: 0.25em;
    border-radius: 0.25em;
    background-color: rgba(#616161, 0.1);
    color: #616161;
    box-decoration-break: clone;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid rgba(#0d0d0d, 0.1);
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

  li > ol {
    counter-reset: list;
    margin: 0;
  }

  li > ol > li {
    list-style: none;
    position: relative;
  }

  li > ol > li:before {
    counter-increment: list;
    content: counter(list, lower-alpha) ') ';
    position: absolute;
    left: -1.4em;
  }
}

.tableToolbar {
  position: absolute !important;
  width: 33% !important;
  left: 30%;
}

.bubble-menu-icons {
  gap: 4px;
  background: white;
  padding: 8px 10px;
  border: 1px solid #efefef;
  button {
    border: none;
    padding: 6px 10px;
    background: white;
    border-radius: 4px;
    &:hover {
      background: #efefef;
    }
  }
  .uil {
    font-size: 22px;
    // color: red;
  }
}

.toolbar-sticky {
  position: sticky;
  top: 60px;
  z-index: 999;
  background: white;
}

.code-min-height {
  min-height: 140px;
}

.border-top-light {
  border-top: 1px solid #edeef2;
}
</style>
<style lang="scss">
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
