<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="font-weight-bold">Sort Items</h3>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div>
            <p class="text-muted">Choose one of the below options for toplist sorting.</p>
            <base-radio
              class="ml-3"
              :data="availableStatuses"
              v-model="status"
              name="status"
              :inlineLayout="false"
            />
          </div>
          <div class="confirm-wrapper">
            <base-checkbox v-model="sortingConfirmed" name="confirmSorting" label="Confirm sorting" />
            <div class="callout callout-danger">
              Please confirm this toplist does not have paid positions before sorting. Toplists with paid positions should not be sorted.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary mr-2" :class="{ loading: loading }" @click="close">Close</button>
          <button class="btn btn-primary ml-2" :class="{ loading: loading }" :disabled="!sortingConfirmed" @click="submit">
              Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@atoms/fields/base-checkbox.vue';
import BaseRadio from '@atoms/fields/base-radio.vue';
import { mapGetters } from 'vuex';

export default {
  components: { BaseCheckbox, BaseRadio },
  mounted() {
    if (this.$route.fullPath === this.getStatus.item) {
      this.status = this.availableStatuses.find(status => status === this.getStatus.value) || {};
      this.sortingConfirmed = true;
    }
  },
  data() {
    return {
      status: {},
      loading: false,
      sortingConfirmed: false,
    };
  },
  computed: {
    availableStatuses() {
      return ['Highest rating', 'Newly added', 'Recently updated'];
    },
    ...mapGetters({
      getStatus: 'normalToplist/getStatus',
    }),
  },
  methods: {
    show() {
      this.$refs.modal.classList.add('show');
    },
    close() {
      this.loading = false;
      this.$refs.modal && this.$refs.modal.classList.remove('show');
    },
    submit() {
      this.loading = true;
      this.$emit('submit-sorting', this.status);
      this.close();
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  min-width: fit-content !important;
}
.callout {
  margin-bottom: 0 !important;
  margin-left: 8px;
}
.confirm-wrapper {
  margin-top: 24px;
}
</style>