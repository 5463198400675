<template>
  <bubble-menu
    :editor="editor"
    :tippy-options="tippyOptions"
    :updateDelay="0"
    :shouldShow="() => true"
    v-if="editor"
    class="d-flex align-items-center"
  >
    <base-bubble
      :editor="editor"
      @setLink="$emit('setLink')"
      @setAnchor="$emit('setAnchor')"
      :canOpenAnchorLink="canOpenAnchorLink"
      :isAnchor="isAnchor"
      :selectedText="selectedText"
      v-if="isBase"
    />
    <table-bubble v-else-if="shouldShowTableBubble" :editor="editor" />
    <image-bubble
      @setImage="$emit('setImage')"
      @setAltText="$emit('setAltText')"
      @setTitle="$emit('setTitle')"
      @setLink="$emit('setLink')"
      :editor="editor"
      :isTable="isTable"
      :canOpenAnchorLink="canOpenAnchorLink"
      :isActiveImage="isActiveImage"
      :isActiveFigure="isActiveFigure"
      v-else-if="isImageBubble && !isTableSelected"
    />
    <panel-popup v-else-if="isPanelBubble" :editor="editor" />
    <text-bubble :editor="editor" :isAnchor="isAnchor" @setAnchor="$emit('setAnchor')" v-else-if="isAnchor" />
  </bubble-menu>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-3'
import BaseBubble from '../../../../lib/tiptap/components/base-bubble.vue'
import TextBubble from '../../../../lib/tiptap/components/text-bubble.vue'
import TableBubble from '../../../../lib/tiptap/components/table-bubble.vue'
import ImageBubble from '../../../../lib/tiptap/components/image-bubble.vue'
import lastActiveNodes from './lastActiveNode'
import PanelPopup from '../../../../../src/lib/tiptap/components/panel-popup.vue'

export default {
  components: { BubbleMenu, BaseBubble, TextBubble, TableBubble, ImageBubble, PanelPopup },
  props: {
    editor: {},
    required: true,
    isTable: {
      type: Boolean,
      default: false,
    },
    trackingLink: {
      type: Boolean,
      default: false,
    },
    showSource: {
      type: Boolean,
    },
  },
  computed: {
    tippyOptions() {
      const options = {
        maxWidth: 'none',
        zIndex: 1030,
        placement: 'top-start',
      }

      if (!this.trackingLink) {
        options.appendTo = () => this.getDocumentBody
      }

      return options
    },
    isTableSelected() {
      return this.$store.state.tiptap.isTableSelected
    },
    activeNode() {
      let activeNodes = lastActiveNodes(this.editor.state, [
        { type: 'image' },
        { type: 'tableCell' },
        { type: 'tableHeader' },
        { type: 'paragraph' },
        { type: 'figure' },
      ])
      let lastActiveNode = activeNodes[0]

      return lastActiveNode
    },
    isImageBubble() {
      return this.isActiveImage || this.isActiveFigure
    },
    isBase() {
      return this.editor.isActive('link') && this.selectedText && this.activeNode !== 'image'
    },
    canOpenAnchorLink() {
      let linkedElems = ['figure', 'image', 'link']
      const hasTarget = linkedElems.some((el) => Boolean(this.editor.getAttributes(el).target))
      return hasTarget
    },
    isAnchor() {
      return Boolean(
        this.editor.getAttributes('paragraph')['data-anchor-label'] ||
          this.editor.getAttributes('heading')['data-anchor-label']
      )
    },
    getDocumentBody() {
      return document.querySelector('body')
    },

    selectedText() {
      return this.$store.state.tiptap.selectedText
    },
    isActiveImage() {
      return (
        this.activeNode === 'image' &&
        this.editor.isActive('image') &&
        Boolean(this.editor.getAttributes('image').src)
      )
    },
    isActiveFigure() {
      return (
        this.activeNode === 'figure' &&
        this.editor.isActive('figure') &&
        Boolean(this.editor.getAttributes('figure').src)
      )
    },
    shouldShowTableBubble() {
      return (
        (this.isTableSelected || this.activeNode === 'tableCell' || this.activeNode === 'tableHeader') &&
        this.isTable &&
        !this.showSource
      )
    },
    isPanelBubble() {
      return this.editor.isActive('panelBlock')
    },
  },
}
</script>
<style lang="scss">
.header-icon {
  :hover {
    cursor: pointer;
    background: #f5f5f5;
    height: 24px;
  }
}

.cross-through {
  position: relative;
}

.cross-through:after {
  content: ' ';
  height: 2.5px;
  width: 30px;
  background-color: black;
  position: absolute;
  top: 9px;
  left: -5px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.figure-caption-false {
  border: 2.5px solid #000;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  // border-bottom-width: ;
}

.figure-caption-true {
  border: 2.5px solid #0098f7;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.bubble-align {
  transform: scale(0.9);
}
.star-border {
  border: 2.5px solid #000;
  border-radius: 4px;
  transform: scale(0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.figureActive {
  color: #0098f7;
}

.align-itemx-center .bubble-menu-icons {
  .uil {
    width: 24px;
    height: 24px;
  }
}
</style>
