<template>
  <div class="d-flex align-items-center bubble-menu-icons shadow-sm">
    <popper hover arrow placement="top">
      <template #content>
        <span class="popper">Delete Panel</span>
      </template>

      <button id="deletePanel" @click.prevent="deletePanel" slot="reference" class="rounded">
        <i class="uil uil-trash"></i>
      </button>
    </popper>
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deletePanel() {
      this.editor.chain().focus().unsetPanelBlock().run()
    },
  },
}
</script>

<style lang="scss" scoped>
.bubble-menu-icons {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.popper {
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
}
</style>
