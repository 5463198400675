<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    id="toplist-position-modal"
    v-click-outside="close"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-4 d-flex flex-column position-relative">
          <div class="d-flex">
            <h5 class="modal-title">Set Custom Toplist Position</h5>
            <button class="close" v-on:click="close"></button>
          </div>
        </div>
        <div class="modal-body" v-if="item">
          <div class="row">
            <div class="col-4">Set position</div>
            <div class="col-7">Use predefined position</div>
          </div>
          <div class="row">
            <div class="col-4">
              <base-input
                :validationRules="['alpha'].join('|')"
                customClass="mb-0 w-100"
                :autoFocus="true"
                helpText="Enter a position"
                v-model="position"
                :keydownEnter="updatePosition"
                type="number"
                ref="input"
              />
            </div>
            <div class="col-7 d-flex align-items-center">
              <button class="btn btn-outline-info" @click="() => (position = 1)">
                <i class="uil uil-arrow-up" />To top</button
              ><button class="btn btn-outline-info ml-3" @click="() => (position = lowestPosition)">
                <i class="uil uil-arrow-down" /> To bottom
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between py-3" v-if="item">
          <div>{{ item.operator.name }}</div>
          <div>
            <a class="btn btn-secondary ml-3" @click="close">Cancel</a>
            <a class="btn btn-primary ml-3" @click="updatePosition">Set</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemRow from './item-row-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'

export default {
  components: { ItemRow, BaseInput, BaseCheckbox },
  props: {
    lowestPosition: {
      required: true,
      type: Number,
    },
    storeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      position: null,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  computed: {
    items() {
      return this.$store.getters[`${this.storeName}/getItems`]
    },
  },
  methods: {
    show(item, position) {
      this.position = position
      this.item = item
      this.$refs.modal.classList.add('show')
      this.$nextTick(() => {
        this.$refs.input.setFocused()
      })
    },
    updatePosition() {
      let clone = [...this.items]

      const currentIndex = clone.findIndex((item) => item.id === this.item.id)
      const [movedItem] = clone.splice(currentIndex, 1)

      clone.splice(this.position - 1, 0, movedItem)

      const updatedItems = clone.map((item, index) => ({
        ...item,
        sort_nr: index + 1,
      }))

      this.$store.commit(`${this.storeName}/updateItemsSortOrder`, updatedItems)
      this.close()
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
<style lang="scss" scoped>
.position-left-size {
  width: 160px;
}
</style>
