import Api from './Api'

const path = 'hercules/games/affiliate-games'

export default {
  getAll(affiliateId) {
    return Api().get(`${path}`, {
      params: {
        affiliate_id: affiliateId
      }
    })
  },
  get(data) {
    return Api().get(`${path}`, { params: data })
  },
  getOne(id, data) {
    data.id = id
    return Api().get(path, {
      params: data
    })
  },
  post(data) {
    return Api().post(`${path}`, data)
  },
  remove(data) {
    return Api().delete(`${path}/${data.id}`)
  },
  update(data) {
    return Api().put(`${path}/${data.id}`, data)
  }
}
