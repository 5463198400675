<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-size">
      <div class="modal-content" v-if="object.properties">
        <div class="modal-header">
          <h5 class="modal-title">Details</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <!-- Handle for 'deleted' events -->
          <div class="row row-wrapper" v-if="object.event === 'deleted'">
            <!-- Only show old data -->
            <div class="col col-wrapper">
              <label>Old</label>
              <div v-html="diffHtmlOld"></div>
            </div>
          </div>

          <!-- Handle for 'created' events -->
          <div class="row" v-if="object.event === 'created'">
            <!-- Only show updated data -->
            <div class="col">
              <label>Updated</label>
              <div v-html="diffHtmlUpdated"></div>
            </div>
          </div>

          <!-- Handle for 'updated' events -->
          <div class="row" v-if="object.event !== 'created' && object.event !== 'deleted'">
            <!-- Old column -->
            <div class="col">
              <label>Old</label>
              <div v-html="diffHtmlOld"></div>
            </div>
            <!-- Updated column -->
            <div class="col">
              <label>Updated</label>
              <div v-html="diffHtmlUpdated"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, defineExpose } from 'vue'

const object = ref({})
const diffHtmlOld = ref('')
const diffHtmlUpdated = ref('')
const modal = ref(null)

const parseJson = (value) => (value ? value : {})

const show = () => {
  nextTick(() => {
    if (modal.value) {
      modal.value.classList.add('show')
      generateDiff()
    }
  })
}

const close = () => {
  if (modal.value) {
    modal.value.classList.remove('show')
  }
}

const generateDiff = () => {
  const oldData = parseJson(object.value.properties?.old)
  const updatedData = parseJson(object.value.properties?.attributes)

  const { oldHtml, updatedHtml } = formatTextDiff(oldData, updatedData)

  diffHtmlOld.value = oldHtml
  diffHtmlUpdated.value = updatedHtml
}

const formatTextDiff = (oldData, updatedData) => {
  const oldHtml = []
  const updatedHtml = []

  const allKeys = new Set([...Object.keys(oldData), ...Object.keys(updatedData)])

  allKeys.forEach((key) => {
    let oldValue = oldData[key] !== undefined ? oldData[key] : ''
    let newValue = updatedData[key] !== undefined ? updatedData[key] : ''

    // Handle objects and arrays by converting them into formatted JSON strings
    if (typeof oldValue === 'object' && oldValue !== null) {
      oldValue = JSON.stringify(oldValue, null, 2).replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;')
    }
    if (typeof newValue === 'object' && newValue !== null) {
      newValue = JSON.stringify(newValue, null, 2).replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;')
    }

    if (oldValue !== newValue) {
      const { oldResult, newResult } = highlightTextDiff(String(oldValue), String(newValue))
      oldHtml.push(
        `<div class="d-flex align-items-start wrapper"> <span class="row-diff-highlight-old custom-span">${key} </span> : <span class="row-diff-highlight-old"> ${oldResult}</span></div>`
      )
      updatedHtml.push(
        `<div class="d-flex align-items-start wrapper"><span class="row-diff-highlight-new custom-span">${key} </span> : <span class="row-diff-highlight-new"> ${newResult}</span></div>`
      )
    } else {
      oldHtml.push(
        `<span class="custom-span">${key} </span> : <span class="custom-span">${oldValue}</span><br>`
      )
      updatedHtml.push(
        `<span class="custom-span">${key} </span> : <span class="custom-span">${newValue}</span><br>`
      )
    }
  })

  return {
    oldHtml: oldHtml.join(''),
    updatedHtml: updatedHtml.join(''),
  }
}

const highlightTextDiff = (oldText, newText) => {
  let resultOld = ''
  let resultNew = ''

  if (oldText === newText) {
    resultOld = oldText
    resultNew = newText
    return { oldResult: resultOld, newResult: resultNew }
  }

  let startIndex = 0
  while (
    startIndex < oldText.length &&
    startIndex < newText.length &&
    oldText[startIndex] === newText[startIndex]
  ) {
    startIndex++
  }

  let endIndexOld = oldText.length - 1
  let endIndexNew = newText.length - 1
  while (
    endIndexOld >= startIndex &&
    endIndexNew >= startIndex &&
    oldText[endIndexOld] === newText[endIndexNew]
  ) {
    endIndexOld--
    endIndexNew--
  }

  resultOld += oldText.slice(0, startIndex)
  resultNew += newText.slice(0, startIndex)

  if (startIndex <= endIndexOld) {
    resultOld += `<span class="highlight-old custom-span">${oldText.slice(
      startIndex,
      endIndexOld + 1
    )}</span>`
  }

  if (startIndex <= endIndexNew) {
    resultNew += `<span class="highlight-new custom-span">${newText.slice(
      startIndex,
      endIndexNew + 1
    )}</span>`
  }

  resultOld += oldText.slice(endIndexOld + 1)
  resultNew += newText.slice(endIndexNew + 1)

  return { oldResult: resultOld, newResult: resultNew }
}

defineExpose({
  show,
  close,
  object,
})
</script>

<style lang="scss">
.row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.col-wrapper {
  flex: 0 0 47%;
}
.row-diff-highlight-old {
  padding: 6px;
  background-color: #fbe9eb;
  min-height: 30px;
}

.row-diff-highlight-new {
  padding: 6px;
  background-color: #ecfdf0;
}

.highlight-old {
  background-color: #ffcccc;
  color: red;
}

.highlight-new {
  background-color: #ccffcc;
  color: green;
}
.modal-size {
  min-width: fit-content;
}
.custom-span {
  margin-bottom: 8px;
  white-space: pre-wrap;
}
.wrapper span {
  margin: 0;
}
</style>
