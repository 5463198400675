<template>
    <div class="container-fluid">
        <div class="bg-white card rounded">
            <div class="card-header">
                <div class="card-body p-0">
                    <div class="card-body p-0">
                        <div class="filter-container pl-2">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
            <slot name="cards"/>
        </div>
    </div>
</template>
<style scoped>
  .filter-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  @media (min-width: 1200px) {
    .filter-container {
      flex-direction: row;
    }
  }
  .card-header {
    padding: 12px !important;
  }
  .card-body {
    width: 100% !important;
  }
</style>