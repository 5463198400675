<template>
  <data-table
      model="CryptoBroker"
      service-file="CryptoBrokersService"
      path="hercules/cryptos/brokers"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :show-edit-modal="false"
      ref="form"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'updated_at', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        enabled: 0
      }
    }
  }
}
</script>