import { Node, mergeAttributes } from '@tiptap/core'
import { NodeSelection } from '@tiptap/pm/state'
import { Column } from './column'
import { ColumnSelection } from './column-selection'
import { buildColumn, buildNColumns, buildColumnBlock, findParentNodeClosestToPos } from './utils'

export const ColumnBlock = Node.create({
  name: 'columnBlock',
  group: 'block',
  content: 'column{2,}',
  isolating: true,
  selectable: true,

  addOptions() {
    return {
      nestedColumns: false,
      columnType: Column,
    }
  },

  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: (element) => element.getAttribute('id'),
        renderHTML: (attributes) => {
          const attr = {}
          if (attributes.id) {
            attr.id = attributes.id
          }
          return attr
        },
      },
    }
  },

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }]
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, {
      'data-type': this.name,
      class: 'two-column-block',
    })
    return ['div', attrs, 0]
  },

  addCommands() {
    const unsetColumns =
      () =>
      ({ tr, dispatch }) => {
        try {
          if (!dispatch) {
            return
          }

          const pos = tr.selection.$from
          const where = ({ node }) => {
            if (!this.options.nestedColumns && node.type === this.type) {
              return true
            }
            return node.type === this.type
          }
          const firstAncestor = findParentNodeClosestToPos(pos, where)
          if (firstAncestor === undefined) {
            return
          }

          let nodes = []
          firstAncestor.node.descendants((node, _, parent) => {
            if (parent?.type.name === Column.name) {
              nodes.push(node)
            }
          })
          nodes = nodes.reverse().filter((node) => node.content.size > 0)

          const resolvedPos = tr.doc.resolve(firstAncestor.pos)
          const sel = new NodeSelection(resolvedPos)

          tr = tr.setSelection(sel)
          nodes.forEach((node) => {
            tr = tr.insert(firstAncestor.pos, node)
          })
          tr = tr.deleteSelection()
          return dispatch(tr)
        } catch (error) {
          console.error(error)
        }
      }

    const setColumns =
      (n, keepContent = false) =>
      ({ tr, dispatch }) => {
        try {
          const { doc, selection } = tr
          if (!dispatch) {
            console.log('no dispatch')
            return
          }

          const sel = new ColumnSelection(selection)
          sel.expandSelection(doc)

          const { openStart, openEnd } = sel.content()
          if (openStart !== openEnd) {
            console.warn('failed depth check')
            return
          }

          let columnBlock
          if (keepContent) {
            const content = sel.content().toJSON()
            const firstColumn = buildColumn(content)
            const otherColumns = buildNColumns(n - 1)
            columnBlock = buildColumnBlock({
              content: [firstColumn, ...otherColumns],
            })
          } else {
            const columns = buildNColumns(n)
            columnBlock = buildColumnBlock({ content: columns })
          }
          const newNode = doc.type.schema.nodeFromJSON(columnBlock)
          if (newNode === null) {
            return
          }

          const parent = sel.$anchor.parent.type
          const canAcceptColumnBlockChild = (par) => {
            if (!par.contentMatch.matchType(this.type)) {
              return false
            }

            if (!this.options.nestedColumns && par.name === Column.name) {
              return false
            }

            return true
          }
          if (!canAcceptColumnBlockChild(parent)) {
            console.warn('content not allowed')
            return
          }

          tr = tr.setSelection(sel)
          tr = tr.replaceSelectionWith(newNode, false)
          return dispatch(tr)
        } catch (error) {
          console.error(error)
        }
      }

    return {
      unsetColumns,
      setColumns,
    }
  },
})
