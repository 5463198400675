import { findParentNodeClosestToPos } from './multi-columns/utils.js'
import { NodeSelection, TextSelection } from '@tiptap/pm/state'

export default {
  data() {
    return {
      showLayoutModal: false,
      layoutOptions: [
        { label: 'Two Columns', value: 2, icon: 'uil uil-columns' },
        { label: 'Three Columns', value: 3, icon: 'uil uil-grids' },
        { label: 'Left Sidebar', value: 'leftSidebar', icon: 'uil uil-left-arrow-to-left' },
        { label: 'Right Sidebar', value: 'rightSidebar', icon: 'uil uil-arrow-to-right' },
        { label: 'Copy Layout', value: 'copy', icon: 'uil uil-copy' },
        { label: 'Delete Columns', value: 'unset', icon: 'uil uil-trash-alt' },
      ],
    }
  },
  methods: {
    showLayoutOptions() {
      this.showLayoutModal = !this.showLayoutModal
    },
    applyLayout(value) {
      if (!this.editor) return

      if (value === 'unset') {
        this.editor
          .chain()
          .focus()
          .command(({ tr, dispatch }) => {
            const { doc, selection } = tr
            const pos = selection.$from
            const where = ({ node }) =>
              node.type && (node.type.name === 'columnBlock' || node.type.name === 'threeColumnBlock')
            const firstAncestor = findParentNodeClosestToPos(pos, where)

            if (!firstAncestor) {
              return false
            }

            const resolvedPos = doc.resolve(firstAncestor.pos)
            const sel = new NodeSelection(resolvedPos)

            tr = tr.setSelection(sel).deleteSelection()
            dispatch(tr)
            return true
          })
          .run()
      } else if (value === 'copy') {
        const { state, view } = this.editor
        const { selection } = state
        const { $from } = selection

        const parentNode = findParentNodeClosestToPos(
          $from,
          ({ node }) =>
            node.type && (node.type.name === 'columnBlock' || node.type.name === 'threeColumnBlock')
        )

        if (!parentNode) return

        const columnContent = parentNode.node.content
        const nodeAttrs = parentNode.node.attrs // Copy the attributes of the parent node
        let newColumnBlock

        if (parentNode.node.type.name === 'columnBlock') {
          newColumnBlock = state.schema.nodes.columnBlock.create(nodeAttrs, columnContent)
        } else if (parentNode.node.type.name === 'threeColumnBlock') {
          newColumnBlock = state.schema.nodes.threeColumnBlock.create(nodeAttrs, columnContent)
        }

        const insertPos = parentNode.pos + parentNode.node.nodeSize
        const newTr = state.tr.insert(insertPos, newColumnBlock)

        view.dispatch(newTr)
        this.editor.commands.setTextSelection(insertPos + 1)
      } else if (value === 'leftSidebar' || value === 'rightSidebar') {
        this.editor
          .chain()
          .focus()
          .command(({ tr, state, dispatch }) => {
            const { selection } = state
            const { $from } = selection

            const parentNode = findParentNodeClosestToPos(
              $from,
              ({ node }) => (node.type && node.type.name === 'columnBlock') || node.type.name === 'paragraph'
            )

            if (!parentNode) return false

            const initialContent = state.schema.nodes.paragraph.create({}, state.schema.text(' '))

            let columnBlockId = 'left-sidebar'
            if (value === 'rightSidebar') {
              columnBlockId = 'right-sidebar'
            }

            const leftColumn = state.schema.nodes.column.create({}, initialContent)
            const rightColumn = state.schema.nodes.column.create({}, initialContent)

            const newColumnBlock = state.schema.nodes.columnBlock.create({ id: columnBlockId }, [
              leftColumn,
              rightColumn,
            ])
            const newTr = tr.replaceWith(
              parentNode.pos,
              parentNode.pos + parentNode.node.nodeSize,
              newColumnBlock
            )

            dispatch(newTr)
            return true
          })
          .run()
        this.setFocusToFirstColumn()
      } else if (value === 3) {
        this.editor.chain().focus().setThreeLayoutColumns().run()
        this.setFocusToFirstColumn()
      } else {
        this.editor.chain().focus().setColumns(value).run()
        this.setFocusToFirstColumn()
      }
      this.showLayoutModal = false
    },
    setFocusToFirstColumn() {
      const { state, view } = this.editor
      const { selection } = state
      const { $from } = selection

      const parentNode = findParentNodeClosestToPos(
        $from,
        ({ node }) => node.type.name === 'columnBlock' || node.type.name === 'threeColumnBlock'
      )

      if (!parentNode) return

      const firstColumnPos = parentNode.pos + 1

      view.dispatch(state.tr.setSelection(TextSelection.create(state.doc, firstColumnPos)))
    },
  },
}
