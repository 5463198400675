import Api from './Api'

export default {
  getOne(id) {
    return Api().get(`hercules/operators/gambling-companies/${id}`)
  },
  post(data) {
    return Api().post(`hercules/operators/gambling-companies`, data)
  },
  update(data) {
    return Api().put(`hercules/operators/gambling-companies/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/operators/gambling-companies/${row.id}`)
  }
}
