import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/games/games/${id}`, {
      params: data,
    })
  },
  post(data) {
    return Api().post(`hercules/games/games`, data)
  },
  update(data) {
    let payload = data
    if (payload.jackpots === 0 || payload.jackpots === false) {
      payload.jackpot_type = 'unknown(null)'
    } else {
      payload.jackpot_type = data.jackpot_type
    }
    return Api().put(`hercules/games/games/${data.id}`, payload)
  },
  remove(data) {
    return Api().delete(`hercules/games/games/${data.id}`)
  },
}
