<template>
  <div class="modal" v-bind:class="{ in: active }">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">{{ actionTitlePrefix }} link</h5>
          <button class="close p-0 m-0" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-0" v-bind:class="{ loading: isLoading }">
          <base-radio
            :data="['Internal', 'External']"
            :modelValue="internal ? 'Internal' : 'External'"
            @update:modelValue="
              (option) => $store.commit('tiptap/setLinkInternal', (internal = option.value === 'Internal'))
            "
            name="Page Type"
            label="Type"
            :inlineLayout="true"
            id="pageTypeRadio"
          />

          <model-select
            ref="pageSelect"
            v-if="internal"
            model="page"
            modalSizeClass="modal-lg2"
            :parameters="parameters"
            :value="page && page.id"
            :value-label="pageText"
            @valuePicked="pageSelected"
            :returnFullObject="true"
          />

          <base-input
            :required="true"
            v-if="!internal"
            label="Url"
            v-model="externalURL"
            ref="externalUrl"
            :helpText="haveProtocol"
          />

          <base-input
            :required="!internal"
            v-if="!editor.isActive('image') && !editor.isActive('figure')"
            label="Text"
            v-model="customText"
          />

          <base-input :required="false" label="Class" v-model="hrefClass" ref="hrefClass" />
          <div class="d-flex">
            <base-checkbox
              v-if="!internal"
              id="newTab"
              name="newtab"
              :switch="true"
              v-model="newTab"
              wrapperClass="mt-4 mb-3 mr-3"
              label="Open in new tab"
            />
            <base-checkbox
              v-if="!internal"
              id="noFollow"
              name="nofollow"
              :switch="true"
              v-model="noFollow"
              wrapperClass="mt-4 mb-3"
              label="No Follow"
            />
          </div>
          <hr />
          <div>
            <base-code-text
              label="Preview"
              :code="showLinkPreview"
              language="text/html"
              :readOnly="true"
              :heightAuto="true"
              :showLineNumber="false"
              ref="linkPreview"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-info mr-2 ml-2" @click="submit">
            <i class="uil uil-link"></i> {{ actionTitlePrefix }} link
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCodeText from '@atoms/fields/base-code-text.vue'

export default {
  components: {
    ModelSelect,
    BaseCheckbox,
    BaseRadio,
    BaseInput,
    BaseCodeText,
  },
  data() {
    return {
      active: false,
      attributes: { href: null, pageId: null, noFollow: false, newTab: false, ref: null },
      pageText: null,
      validateUrl: false,
      previousText: '',
      haveProtocol: '',
    }
  },
  props: {
    actionTitlePrefix: {
      type: String,
      default: 'Add',
    },
    marketId: {},
    siteId: {},
    editor: {},
  },
  watch: {
    externalURL(newValue) {
      if (newValue && !newValue.startsWith('http://') && !newValue.startsWith('https://')) {
        this.haveProtocol =
          'Include a protocol (e.g., http:// or https://) in the URL to ensure it opens as an external link, not a relative path.'
      } else {
        this.haveProtocol = ''
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.tiptap.isLoading,
    }),
    getLinkPreview() {
      if (this.isLoading) return ''

      const targetValue = this.newTab && !this.internal ? '_blank' : ''
      let relValue = this.noFollow ? 'nofollow' : ''

      if (this.newTab) {
        relValue = 'noopener noreferrer'
      }
      if (this.newTab && this.noFollow) {
        relValue = 'noopener noreferrer nofollow'
      }

      let hrefValue = ''

      const content = this.$store.state.tiptap.isImageSelected
        ? '[image]'
        : this.$store.state.tiptap.selectedText &&
          this.$store.state.tiptap.selectedText.replaceAll(/\u200b/g, '')

      if (!this.internal) {
        hrefValue = this.externalURL
      } else if (this.page) {
        hrefValue = this.getHref
      }

      const classValue = this.hrefClass || ''

      return { hrefValue, targetValue, relValue, content, classValue }
    },
    getHref() {
      if (this.internal && this.page) {
        let internalHref = `/${this.page.path}`
        if (this.page.site_market && this.page.site_market.path_prefix) {
          internalHref = `/${this.page.site_market.path_prefix}${internalHref}`
        }

        if (internalHref.startsWith('//')) {
          internalHref = internalHref.substring(1)
        }
        if (internalHref.endsWith('//')) {
          internalHref = internalHref.substring(0, internalHref.length - 1)
        }

        return internalHref
      }
      return this.externalURL
    },
    showLinkPreview() {
      return !this.getLinkPreview.hrefValue || this.getLinkPreview.hrefValue === '/undefined'
        ? this.showBeforeLinkPreview
        : this.buildPreview
    },
    showBeforeLinkPreview() {
      return 'No link to preview yet...'
    },
    buildPreview() {
      const { hrefValue, targetValue, relValue, content, classValue } = this.getLinkPreview

      let linkHtml = `<a href="${hrefValue}"`

      if (relValue) {
        linkHtml += ` rel="${relValue}"`
      }

      if (targetValue) {
        linkHtml += ` target="${targetValue}"`
      }

      if (classValue) {
        linkHtml += ` class="${classValue}"`
      }

      linkHtml += `>${content}</a>`

      return linkHtml
    },
    internal: {
      get() {
        return this.$store.state.tiptap.linkInternal
      },
      set(value) {
        this.$store.commit('tiptap/setLinkInternal', value)
        // clear page select
        if (this.page) {
          this.$refs.pageSelect.clearAndEnable()
          this.attributes = { href: null, pageId: null, class: null, label: null, target: null, ref: null }
        }
        // clear preview stuff
        this.$store.commit('tiptap/setLinkExternalURL', null)
        this.$store.commit('tiptap/setLinkNewTab', false)
        this.$store.commit('tiptap/setLinkNoFollow', false)
      },
    },
    customText: {
      get() {
        return this.$store.state.tiptap.selectedText
      },
      set(value) {
        this.$store.commit('tiptap/setSelectedText', value)
      },
    },
    page: {
      get() {
        return this.$store.state.tiptap.linkPage
      },
      set(value) {
        this.$store.commit('tiptap/setLinkPage', value)
      },
    },
    newTab: {
      get() {
        return this.$store.state.tiptap.linkNewTab
      },
      set(value) {
        this.$store.commit('tiptap/setLinkNewTab', value)
      },
    },
    noFollow: {
      get() {
        return this.$store.state.tiptap.linkNoFollow
      },
      set(value) {
        this.$store.commit('tiptap/setLinkNoFollow', value)
      },
    },
    externalURL: {
      get() {
        return this.$store.state.tiptap.linkExternalURL
      },
      set(value) {
        this.$store.commit('tiptap/setLinkExternalURL', value)
      },
    },
    hrefClass: {
      get() {
        return this.$store.state.tiptap.linkHrefClass
      },
      set(value) {
        this.$store.commit('tiptap/setLinkHrefClass', value)
      },
    },
    parameters() {
      let parametersObject = {
        status: 'active',
        exact_match: 'status',
      }

      if (this.marketId) {
        parametersObject.market_id = this.marketId
      }
      if (this.siteId) {
        parametersObject.site_id = this.siteId
      }

      return parametersObject
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
    this.previousText = this.customText
    document.addEventListener('keyup', this.escClose)
  },
  destroyed() {
    document.removeEventListener('keyup', this.escClose)
  },
  methods: {
    escClose(event) {
      if (event.key === 'Escape' && !this.$refs['pageSelect'].showModal) {
        this.close()
      }
    },
    async show(attributes = {}) {
      this.active = true

      this.attributes = attributes

      if (attributes.class) {
        this.hrefClass = attributes.class
      } else {
        this.hrefClass = ''
      }

      if (attributes.pageId) {
        await this.getPage(attributes.pageId)
      } else {
        this.$store.commit('tiptap/setLinkInternal', false)
        this.$store.commit('tiptap/setIsLoading', false)
      }
      // set rel accordingly
      if (!this.attributes?.rel) {
        return
      }
      let splitRel = this.attributes.rel.split(' ')

      this.$store.commit('tiptap/setLinkNoFollow', splitRel.includes('nofollow'))
      this.$store.commit(
        'tiptap/setLinkNewTab',
        splitRel.includes('noopener') && splitRel.includes('noreferrer')
      )
    },
    async getPage(pageId) {
      this.$store.commit('tiptap/setIsLoading', true)
      try {
        const response = await this.$http.get(`hercules/sites/pages/${pageId}`)
        if (response.data && response.data.result && response.data.result.length === 1) {
          this.$store.commit('tiptap/setLinkPage', response.data.result[0])
          this.pageText = this.page.title
        } else {
          this.showErrorMessages('Error fetching page link.')
        }
      } catch (error) {
        console.error('Error fetching page:', error)
      } finally {
        this.$store.commit('tiptap/setIsLoading', false)
      }
    },
    validate() {
      if (!this.customText?.length && !this.editor.isActive('image') && !this.editor.isActive('figure')) {
        return 'empty_text_input'
      }
      if (this.internal) {
        if (!this.page) {
          return 'page_missing'
        }
        return 'valid'
      } else {
        if (!this.externalURL?.length) {
          return 'invalid_url'
        }
        return 'valid'
      }
    },
    async submit() {
      let attributes = { rel: null, target: null, class: null }
      const { hrefValue, targetValue } = this.getLinkPreview
      const validationCode = this.validate()

      if (validationCode !== 'valid') {
        const errorMessages = {
          page_missing: 'Please insert page.',
          invalid_url: 'Please write a valid URL.',
          empty_text_input: 'Text input cannot be empty.',
        }

        this.showErrorMessages(errorMessages[validationCode])
        return
      }

      if (this.internal) {
        attributes.pageId = this.page.id
        attributes.label = this.page.title
      } else if (!this.internal && this.externalURL) {
        if (this.noFollow) {
          attributes.rel = 'nofollow'
        }
        if (this.newTab) {
          attributes.rel = 'noopener noreferrer'
        }
        if (this.newTab && this.noFollow) {
          attributes.rel = 'noopener noreferrer nofollow'
        }

        attributes.target = targetValue
      }

      attributes.href = hrefValue

      if (this.hrefClass) {
        attributes.class = this.hrefClass
      }

      if (this.editor.isActive('figure')) {
        this.editor.chain().focus().figureToLink(attributes).run()
        this.close()
        return
      } else if (this.editor.isActive('image')) {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        this.editor.chain().focus().extendMarkRange('link').setLink(attributes).run()
        this.close()
        return
      } else if (this.customText.length === 0 || this.previousText !== this.customText) {
        this.editor.chain().focus().unsetLink().run()
        this.editor.chain().focus().updateLinkContent(attributes, this.customText, this.internal).run()
        this.close()
        return
      } else if (this.editor.isActive('link')) {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        this.editor.chain().focus().extendMarkRange('link').setLink(attributes).run()
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink(attributes).run()
      }

      this.close()
    },
    close() {
      this.$emit('closeLink')
      this.$store.commit('tiptap/setSelectedText', '')
      this.$store.commit('tiptap/setClearLinkModal')
    },
    pageSelected(value, label) {
      this.$store.commit('tiptap/setLinkPage', value)
      this.pageText = label
      if (!this.customText) {
        this.$store.commit('tiptap/setLinkText', value?.title ? value.title : null)
      }
    },
  },
}
</script>
<style scoped>
.loading {
  opacity: 0.5;
}
</style>
