import Api from '../Api'
import NotificationHelper from '@mixins/NotificationHelper'

const buildQueryString = (filters) => {
  return Object.keys(filters)
    .map((key) => key + '=' + filters[key])
    .join('&')
}

export default {
  mixins: [NotificationHelper],
  getOne(id, data) {
    return Api()
      .get(`koala/v1/accounts/${id}`, {
        params: data,
      })
      .then((response) => {
        const initialAccountData = response.data.result[0]

        if (initialAccountData && initialAccountData.migrated_to_id) {
          return Api()
            .get(`koala/v1/accounts/${initialAccountData.migrated_to_id}`)
            .then((migratedResponse) => {
              const migratedAccountData = migratedResponse.data.result[0]

              initialAccountData.operators = migratedAccountData.operator.id
              initialAccountData.accounts = migratedAccountData.id

              return Promise.resolve({ data: { result: [initialAccountData] } })
            })
            .catch((error) => {
              console.error('Error fetching migrated account details:', error)
              throw error
            })
        } else {
          return Promise.resolve({ data: { result: [initialAccountData] } })
        }
      })
      .catch((error) => {
        console.error('Error fetching initial account details:', error)
        throw error
      })
  },

  get() {
    return Api().get(`koala/v1/accounts`)
  },
  post(data) {
    if (!this.validateRequiredFields(data)) {
      return
    }

    const {
      account_manager_id,
      account_manager_name,
      active,
      business_unit,
      currency,
      notes,
      operator,
      migrated_to_id,
      username,
      proxy,
      status,
      migrated_to_date,
      // api_username,
      // api_key,
      // password,
    } = data

    const isActive = !!active
    let payload = {
      account_manager_id: account_manager_id == null ? -1 : account_manager_id,
      account_manager_name: account_manager_id == null ? 'UNSET' : account_manager_name,
      active: isActive,
      business_unit,
      currency,
      notes,
      operator,
      status,
      username,
      proxy,
      // api_username: api_username == null ? '' : api_username,
      // api_key: api_key == null ? '' : api_key,
      // password: password == null ? '' : password,
    }
    if (status.includes('Migrated') || status.includes('Merged')) {
      payload.migrated_to_id = migrated_to_id
      payload.migration_date = migrated_to_date
    }
    return Api().post(`koala/v1/accounts`, payload)
  },

  remove(data) {
    return Api().delete(`koala/v1/accounts/${data.id}`, data)
  },

  update(data) {
    const {
      id,
      account_manager_id,
      account_manager_name,
      active,
      currency,
      notes,
      username,
      proxy,
      status,
      migrated_to_id,
      migrated_to_date,
    } = data

    const isMigratedOrMerged =
      typeof status === 'string' && (status.includes('Migrated') || status.includes('Merged'))

    let payload = {
      account_manager_id: account_manager_id == null ? -1 : account_manager_id,
      account_manager_name: account_manager_id == null ? 'UNSET' : account_manager_name,
      active: !!active,
      currency,
      notes,
      status,
      username,
      proxy,
    }

    if (isMigratedOrMerged) {
      payload.migrated_to_id = migrated_to_id
      payload.migration_date = migrated_to_date
    }

    return Api().put(`koala/v1/accounts/${id}`, payload)
  },

  patch(data) {
    return Api().patch(`koala/v1/accounts/${data.id}`, { active: data.active })
  },
  // credentials
  getCredentials(id) {
    return Api().get(`koala/v1/accounts/${id}/credentials`)
  },
  bulkByIds(ids, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    return Api().patch(`koala/v1/accounts?${buildQueryString({ ids: ids })}`, changes)
  },
  bulkByFilters(filters, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    return Api().patch(`koala/v1/accounts?${buildQueryString(filters)}`, changes)
  },
  prepareTheDataForBulkEdit(changes, extraData) {
    //Remove operator from payload
    delete changes.operators
    if (changes.migrated_to_date) {
      changes.migration_date = changes.migrated_to_date
      delete changes.migrated_to_date
    }
    if (changes.active === null) {
      changes.active = false
    }
    if (changes.account_manager_id === null) {
      changes.account_manager_id = -1
      changes.account_manager_name = 'UNSET'
    }
    if (changes.account_manager_id) {
      if (Object.hasOwn(extraData, 'users') && extraData.users[changes.account_manager_id]) {
        changes.account_manager_name = extraData.users[changes.account_manager_id]
      }
    }

    if (changes.proxy === null) {
      changes.proxy = '00000000-0000-0000-0000-000000000000'
    }
  },
  validateRequiredFields(data) {
    const required = {
      operator: 'Operator',
      status: 'Status',
      username: 'Username',
      currency: 'Currency',
      business_unit: 'Business Unit',
    }

    let hasErrors = false

    for (let field in required) {
      if (!data[field]) {
        NotificationHelper.showErrorMessage(`${required[field]} is required`)
        hasErrors = true
      }
    }

    return !hasErrors
  },
}
