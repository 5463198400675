/* eslint-disable no-unused-vars */
import App from '@/main.js'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('dynamicForm', ['getFormObject', 'getInitFormObject', 'getFormSubmitted']),
  },
  methods: {
    hasNonEmptyValues(obj) {
      return Object.values(obj).some((value) => value !== '' && value !== null && value !== undefined)
    },
    areObjectsDifferent(obj1, obj2, formType) {
      const filteredObj1 = this.filterEmptyValues(obj1)
      const filteredObj2 = this.filterEmptyValues(obj2)

      const keys1 = Object.keys(filteredObj1)
      const keys2 = Object.keys(filteredObj2)

      if (formType === 'create') {
        // If formType is 'create', check if formObject is not empty
        const isDifferent = keys2.length > 0
        if (isDifferent) {
          console.log('Differences found (create):', filteredObj2)
        }
        return isDifferent
      }

      if (formType === 'edit') {
        // If initFormObject is empty, no need to show confirm
        if (keys1.length === 0) return false

        if (keys1.length !== keys2.length) {
          console.log('Number of keys differ')
          return true
        }

        let differences = []
        for (let key of keys1) {
          if (filteredObj1[key] !== filteredObj2[key]) {
            differences.push({ key, obj1Value: filteredObj1[key], obj2Value: filteredObj2[key] })
          }
        }

        if (differences.length > 0) {
          console.log('Differences found:', differences)
          return true
        }
      }

      return false
    },
    filterEmptyValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== '' && value !== null && value !== undefined && value.length !== 0
        )
      )
    },
    beforeUnLoad(e) {
      if (App.$cookies.isKey('formIsDirty') && App.$cookies.get('formIsDirty') == 'true') {
        e.preventDefault()
        e.returnValue = 'You have unfinished changes!'
      }
    },
    removeBeforeUnloadEvent() {
      let formElements = document.getElementsByClassName('form-changed')
      for (let formElement of formElements) {
        formElement.classList.remove('form-changed')
      }
      window.removeEventListener('beforeunload', this.beforeUnLoad)
      App.$cookies.set('formIsDirty', false)
    },
  },
}
