<template>
  <div class="d-flex justify-content-between flex-wrap align-items-center bubble-menu-icons shadow-sm">
    <base-select
      :loadOnMount="false"
      optionLabelKey="name"
      placeholder="Cell Option"
      ref="cellOptions"
      v-model="selectedOption"
      :customOptions="cellOptions"
      class="mb-0 ml-1"
    />
    <popper hover arrow placement="top">
      <template #content>
        <span class="popper">Toggle Table Header</span>
      </template>
      <button
        id="toggleTableHeader"
        @click.prevent="editor.chain().focus().toggleHeaderRowCustom().run()"
        class="bubble-menu-icons button d-flex justify-content-center align-items-center rounded"
        slot="reference"
      >
        <svg width="22px" height="22px" viewBox="-2.5 -1 21 21" xmlns="http://www.w3.org/2000/svg">
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10%"
          >
            <path
              d="m14.4978951 12.4978973-.0105089-9.99999996c-.0011648-1.10374784-.8962548-1.99789734-2-1.99789734h-9.99999995c-1.0543629 0-1.91816623.81587779-1.99451537 1.85073766l-.00548463.151365.0105133 10.00000004c.0011604 1.1037478.89625045 1.9978973 1.99999889 1.9978973h9.99999776c1.0543618 0 1.9181652-.8158778 1.9945143-1.8507377z"
            />

            <path d="m4.5 4.5v9.817" />

            <path d="m7-2v14" transform="matrix(0 1 -1 0 12.5 -2.5)" />
          </g>
        </svg>
      </button>
    </popper>
    <popper hover arrow placement="top">
      <template #content>
        <span class="popper">Delete Table</span>
      </template>

      <button
        id="deleteTable"
        @click.prevent="editor.chain().focus().deleteTable().run()"
        slot="reference"
        class="rounded"
      >
        <i class="uil uil-trash"></i>
      </button>
    </popper>

    <popper hover arrow placement="top">
      <template #content>
        <span class="popper">Vertical align</span>
      </template>
      <button
        id="alignTop"
        @click.prevent="showVerticalAlign = !showVerticalAlign"
        slot="reference"
        class="rounded"
      >
        <i class="uil uil-align-center-v"></i>
      </button>
    </popper>
    <div class="verticalAlignBubble" v-if="showVerticalAlign">
      <popper hover arrow placement="top">
        <template #content>
          <span class="popper">Align top</span>
        </template>
        <button id="alignTop" @click.prevent="verticalAlignCell('top')" slot="reference" class="rounded">
          <i class="uil uil-vertical-align-top"></i>
        </button>
      </popper>
      <popper hover arrow placement="top">
        <template #content>
          <span class="popper">Align middle</span>
        </template>
        <button
          id="alignMiddle"
          @click.prevent="verticalAlignCell('middle')"
          slot="reference"
          class="rounded"
        >
          <i class="uil uil-vertical-align-center"></i>
        </button>
      </popper>
      <popper hover arrow placement="top">
        <template #content>
          <span class="popper">Align bottom</span>
        </template>
        <button
          id="alignBottom"
          @click.prevent="verticalAlignCell('bottom')"
          slot="reference"
          class="rounded"
        >
          <i class="uil uil-vertical-align-bottom"></i>
        </button>
      </popper>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { BaseSelect },
  props: {
    editor: {},
  },
  data() {
    return {
      selectedCellOption: {},
      showVerticalAlign: false,
    }
  },
  computed: {
    disableMerge() {
      return this.$store.state.tiptap.disableMerge
    },
    headerSelected() {
      return this.$store.state.tiptap.tipTapTableSelectedHeader
    },
    selectedOption: {
      get() {
        return this.selectedCellOption
      },
      set(value) {
        if (value !== 0 && !value) return
        switch (value) {
          case 0:
            this.editor.chain().focus().addColumnBefore().run()
            break
          case 1:
            this.editor.chain().focus().addColumnAfter().run()
            break
          case 2:
            this.editor.chain().focus().addRowBefore().run()
            break
          case 3:
            this.editor.chain().focus().addRowAfter().run()
            break
          case 4:
            this.editor.chain().focus().deleteRow().run()
            break
          case 5:
            this.editor.chain().focus().deleteColumn().run()
            break
          case 6:
            this.editor.chain().focus().mergeCells().run()
            break
          case 7:
            this.editor.chain().focus().splitCell().run()
            break
          default:
            break
        }
        this.selectedCellOption = {}
      },
    },
    cellOptions() {
      return [
        { id: 0, name: 'Add Column Before' },
        { id: 1, name: 'Add Column After' },
        { id: 3, name: 'Add Row Below', disabled: this.headerSelected },
        { id: 2, name: 'Add Row Above' },
        { id: 4, name: 'Delete Row' },
        { id: 5, name: 'Delete Column' },
        { id: 6, name: 'Merge Cells', disabled: this.disableMerge },
        { id: 7, name: 'Split Cell', disabled: this.disableMerge },
      ]
    },
  },
  methods: {
    verticalAlignCell(pos) {
      const tableElement = this.editor.isActive('tableCell') ? 'tableCell' : 'tableHeader'
      const elementAttributes = this.editor.getAttributes(tableElement)
      let currentStyle = elementAttributes.style || ''

      this.editor
        .chain()
        .focus()
        .updateAttributes(tableElement, {
          style: `${currentStyle.length ? currentStyle + ';' : currentStyle} vertical-align: ${pos}`,
        })
        .run()
    },
  },
}
</script>

<style lang="scss" scoped>
.verticalAlignBubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  position: absolute;
  top: 60px;
  right: 0;
  background: #fff;
  border: 1px solid #efefef;
  border-top: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  padding-bottom: 6px;
}
</style>
