<template>
  <div
    @click.stop.prevent="openInNewTab"
    v-if="getExtraValue()"
    class="url-column"
    :class="[!getValue() ? 'url-column-disabled' : '']"
  >
    <span class="href d-flex align-items-center">
      <i class="uil uil-external-link-alt mr-1" :class="[!getValue() ? 'text-lightgray' : 'text-muted']"></i>
      {{ getExtraValue() }}
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    getValue() {
      return this.$attrs.value
    },
    getExtraValue() {
      return this.$attrs.extraValue || ''
    },
    openInNewTab() {
      let url = this.getValue()
      if (url) {
        window.open('/site-pages/' + url, '_blank', 'noopener,noreferrer')
      }
    },
  },
}
</script>

<style scoped>
.href:hover {
  color: var(--blue);
}
.url-column {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: none;
  outline: none;
  width: fit-content;
}
.url-column-disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: lightgray;
}
.text-lightgray {
  color: lightgray;
}
</style>
