<template>
  <div>
    <DashboardTopFilters />
    <DashboardDateFilters />
  </div>
</template>

<script>
import DashboardTopFilters from './components/DashboardTopFilters.vue'
import DashboardDateFilters from './components/DashboardDateFilters.vue'

export default {
  components: {DashboardDateFilters, DashboardTopFilters},
  mounted() {
    this.setTitle();
  }
}

</script>