import Api from './Api'

export default {
  getAll(affiliateId, type = 'operator') {
    return Api().get(`hercules/operators/affiliate-extra-fields`, {
      params: {
        affiliate_id: affiliateId,
        model_type: type,
      },
    })
  },
  get(data) {
    return Api().get(`hercules/operators/affiliate-extra-fields`, {
      params: data,
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/operators/affiliate-extra-fields/${id}`, {
      params: data,
    })
  },
  post(data) {
    return Api().post(`hercules/operators/affiliate-extra-fields`, data)
  },
  remove(data) {
    return Api().delete(`hercules/operators/affiliate-extra-fields/${data.id}`)
  },
  update(data) {
    return Api().put(`hercules/operators/affiliate-extra-fields/${data.id}`, data)
  },
  // Commenting this out due to deprecated feature
  // generateDefaults(id) {
  //   return Api().put(`hercules/operators/affiliate-extra-fields/${id}/generate-defaults`)
  // }
}
