export default {
  getForm() {
    return [
      {
        name: 'label',
        required: true,
        disabled: true,
        type: 'text',
        slug_field: 'short_code',
      },
      {
        name: 'short_code',
        required: true,
        type: 'slug',
        disabled: true,
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: false,
        display_name: 'Market',
        selectLabel: 'label',
        type: 'list-one',
        value_key: 'market.label',
        country_flag: 'country_code',
      },
    ]
  },
  getConfig() {
    return {
      url: 'toplists/lists',
      name: 'List',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
