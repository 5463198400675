<template>
  <div :class="fieldClass">
    <template v-for="field in visibleFields">
      <div v-if="field.type === 'label'" v-bind:key="field.name">
        <dl class="row mb-2">
          <dt class="col-sm-2">{{ field.display_name }}</dt>

          <dd class="col-sm-10">{{ $capitalize(getValue(field)) }}</dd>
        </dl>
      </div>

      <base-list-view
        v-if="field.type == 'list-view'"
        v-bind:key="field.name"
        :value="object[field.name] ? JSON.parse(object[field.name]) : []"
        :field="field"
        :label="field.display_name || $prettyLabels(field.name)"
        :required="field.required"
        v-bind:disabled="isDisabled"
        :readOnly="field.read_only"
        :helpText="field.help_text"
        :formType="formType"
        @input="checkInputChange"
        :ref="field.name"
        :fieldList="field.fields"
        @sync="syncValues"
      />

      <base-average
        v-if="field.type == 'average_number'"
        v-bind:key="field.name"
        :value="object"
        :label="field.display_name || $prettyLabels(field.name)"
        :field="field"
        :readOnly="field.read_only"
        :helpText="field.help_text"
        :labelIndex="field.labelIndex"
        :showAverage="field.show_average"
        :fieldType="field.field_type"
        :sanitizeLabel="field.sanitize_label"
        :allow_text="field.allow_text"
        :required="field.required"
      />

      <base-input
        v-if="
          ['text', 'number', 'hidden', 'password', 'slug'].includes(field.type) && conditionalCheck(field)
        "
        v-bind:key="field.name"
        v-model="object[field.name]"
        :field="field"
        :showLabel="field.showLabel"
        :label="field.display_name || $prettyLabels(field.name)"
        :min="field.min"
        :type="field.type"
        :max="field.max"
        :step="field.step"
        :required="field.required"
        :minlength="field.minlength"
        :maxlength="field.maxlength"
        v-bind:disabled="isDisabled || field.disabled"
        :autoFocus="field.auto_focus"
        :readOnly="field.read_only"
        :helpText="field.help_text"
        :formType="formType"
        @update:modelValue="checkInputChange"
        :ref="field.name"
        :value-modifier="field.value_modifier"
        :valueIsObject="field.value_is_object"
        :ignore1Password="field.ignore1_password"
      />

      <base-number-input
        v-if="['custom-number'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        :field="field"
        v-model="object[field.name]"
        @update:modelValue="checkInputChange"
        :showLabel="field.showLabel"
        :label="field.display_name || $prettyLabels(field.name)"
        :min="field.min"
        :max="field.max"
        :step="field.step"
        :required="field.required"
        :minlength="field.minlength"
        :maxlength="field.maxlength"
        v-bind:disabled="isDisabled || field.disabled"
        :autoFocus="field.auto_focus"
        :readOnly="field.read_only"
        :helpText="field.help_text"
        :formType="formType"
        :ref="field.name"
      />

      <base-rich-text
        v-if="field.type == 'html'"
        v-bind:key="field.name"
        v-model="object[field.name]"
        :field="field"
        :showLabel="field.showLabel"
        :siteId="object.site_id"
        :formType="formType"
        :label="field.display_name"
        :ref="field.name"
        @update:modelValue="(value) => (object[field.name] = value)"
        height="80"
      />

      <base-textarea
        v-if="field.type == 'textarea'"
        v-bind:key="field.name"
        v-model="object[field.name]"
        :label="field.display_name || $prettyLabels(field.name)"
        v-bind:disabled="isDisabled"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :helpText="field.help_text"
        :ref="field.name"
        :rows="field.rows"
      />

      <base-date
        v-if="field.type == 'date' && conditionalCheck(field)"
        v-model="object[field.name]"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        :label="field.display_name || $prettyLabels(field.name)"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :ref="field.name"
        :helpText="field.help_text"
        :modelValue="object[field.value]"
        :config="datePickerConfig(field)"
        @update:modelValue="handleBaseDateChange"
        :defaultDate="field.defaultDate"
      />

      <base-radio
        v-if="field.type == 'radio' && conditionalCheck(field)"
        v-model="object[field.name]"
        v-bind:key="field.name"
        :label="field.display_name || $prettyLabels(field.name)"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :ref="field.name"
        :useNameValue="false"
        :inlineLayout="true"
        :field="field"
        :data="field.values"
        :helpText="field.help_text"
        :disabled="field.disabled || (field.editable === false && formType === 'edit')"
        :notifiedByField="field.notified_by"
        @update:modelValue="clearInput(field)"
      />

      <base-checkbox
        v-if="field.type == 'checkbox' && conditionalCheck(field)"
        v-model="object[field.name]"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        :label="field.display_name || $prettyLabels(field.name)"
        :second_label="$prettyLabels(field.display_name_two)"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :ref="field.name"
        :tooltip="field.tooltip"
        :helpText="field.help_text"
        :switch="field.switch"
        :emitOnGet="field.emitOnGet"
        :saveInStore="field.save_in_store"
      />

      <base-tag
        v-if="field.type == 'tag'"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        v-model="object[field.name]"
        v-on:sync="syncValues"
        :label="field.display_name || $prettyLabels(field.name)"
        :placeholder="field.placeholder"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :helpText="field.help_text"
      />

      <media
        v-if="['base_media', 'base_media_site_specific'].includes(field.type)"
        v-bind:key="field.name"
        v-model="object[field.name]"
        :label="field.display_name || $prettyLabels(field.name)"
        :required="field.required"
        :readOnly="readOnlyBasedOnPermission(field.permission)"
        :helpText="field.help_text"
        :siteSpecific="siteSpecific || field.type === 'base_media_site_specific'"
        :standardised="field.standardised"
      />

      <model-select-modal
        v-if="field.type === 'model_select' && conditionalCheck(field)"
        v-bind:key="field.name"
        :applyTableSize="false"
        :model="field.model"
        :value="object[field.name]"
        :value-label="object[field.value_label]"
        @valuePicked="(value, value_label) => modelSelected(value, value_label, field)"
        :multipleSelect="field.multiple"
        :notified-by-field="field.notified_by"
        :ref="field.name"
        modalSizeClass="modal-lg3"
        :required="field.required"
        :displayName="field.display_name"
        :displayButtonName="field.display_button_name"
        :parameters="parameters"
        :helpText="field.help_text"
        :fromParam="field.fromParam"
        :excludeStatus="field.exclude_status"
        :basicTable="field.basicTable"
        @input="clearInput(field)"
      />

      <base-checkbox-list
        v-if="field.type === 'checkbox-list' && conditionalCheck(field)"
        v-bind:key="field.name"
        :label="field.display_name"
        :options="object[field.name]"
        :selectedOptions="object[field.name].filter((item) => item.active)"
        :selectLabel="field.selectLabel"
        :showPrimary="field.showPrimary"
        :enableToggleAll="field.enableToggleAll"
        :parameters="field.parameters"
        @onChange="(value) => (object[field.name] = value)"
      />

      <base-multiselect
        v-if="['list-many'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        :label="field.display_name || $prettyLabels(field.name)"
        :path="field.search_url"
        :selectLabel="field.selectLabel"
        :trackByOption="field.trackBy"
        :field="field.name"
        :value="getValue(field)"
        :type="field.type"
        :placeholder="field.placeholder"
        :required="field.required"
        :notifiedByField="field.notified_by"
        :loadOnMount="field.fetch_on_load"
        :customOptions="field.options"
        :validate-changes="field.validate_changes"
        :noOptionsPlaceholder="field.noOptionsPlaceholder"
        @input="checkNotifyFields(field)"
        @sync="syncValues"
        :ref="field.name"
        :helpText="field.help_text"
        :quickAdd="field.quick_add"
        :max="field.max"
        :readOnly="field.read_only"
        :disableCopy="field.disableCopy"
        :isDisabled="field.isDisabled"
        :extraOptionLabelKey="field.extraSelectLabel"
        :stylizedExtraLables="field.stylizedExtraLables"
        :isSitesData="field.is_sites_data"
      />
      <base-nested-checkbox
        v-if="['list-many-nested-checkbox'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        :label="field.display_name || $prettyLabels(field.name)"
        :path="field.search_url"
        :selectLabel="field.selectLabel"
        :trackByOption="field.trackBy"
        :field="field.name"
        :value="getValue(field)"
        :type="field.type"
        :placeholder="field.placeholder"
        :required="field.required"
        :notifiedByField="field.notified_by"
        :loadOnMount="field.fetch_on_load"
        :validate-changes="field.validate_changes"
        :noOptionsPlaceholder="field.noOptionsPlaceholder"
        @sync="syncValues"
        :ref="field.name"
        :helpText="field.help_text"
        :max="field.max"
        :readOnly="field.read_only"
        :disableCopy="field.disableCopy"
      />

      <base-multiselect-sorted
        v-if="['list-many-sortable'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        :label="field.display_name || $prettyLabels(field.name)"
        :url="field.search_url"
        :selectLabel="field.selectLabel"
        :trackByOption="field.trackBy"
        :field="field.name"
        :value="getValue(field)"
        :type="field.type"
        :placeholder="field.placeholder"
        :required="field.required"
        :notifiedByField="field.notified_by"
        :loadOnMount="field.fetch_on_load"
        :customOptions="field.options"
        :quickAdd="field.quick_add"
        :fieldObject="field"
        @sync="syncValues"
        :ref="field.name"
      />

      <base-select
        v-if="['list-one'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        :path="field.search_url"
        :label="field.display_name || $prettyLabels(field.name)"
        v-model="object[field.name]"
        :modelValue="getValue(field)"
        :type="field.type"
        :placeholder="field.placeholder"
        :required="field.required"
        :optionLabelKey="field.selectLabel"
        :trackBy="field.trackBy"
        :notifiedByField="field.notified_by"
        :fieldObject="field.notify_fields"
        :loadOnMount="field.fetch_on_load"
        :customOptions="field.options"
        :noOptionsPlaceholder="field.noOptionsPlaceholder"
        @update:modelValue="checkNotifyFields(field)"
        :allowNullOption="true"
        :ref="field.name"
        :disabled="field.disabled || (field.editable === false && formType === 'edit')"
        :helpText="field.help_text"
        :extraOption="field.extra_option"
        :countryFlag="field.country_flag"
        :searchBy="field.searchBy"
        :extraOptionLabelKey="field.extraSelectLabel"
        :showBottomLabel="conditionalLabelCheck(field)"
      />

      <div
        v-if="field.type === 'variable_text'"
        v-bind:key="field.name"
        class="form-group"
        v-on:click.stop="editVariableField(field)"
      >
        <label>{{ field.display_name }}</label>

        <div>
          <span class="btn btn-secondary btn-sm mr-1">
            <i class="uil uil-edit-alt"></i>
            Edit
          </span>
        </div>

        <extra-field-values-edit
          :ref="'variable_modal_' + field.id"
          v-bind:key="field.id"
          :field="field"
          @storeVariableField="storeVariableField"
        />
      </div>

      <base-color
        v-if="['colour_picker'].includes(field.type) && conditionalCheck(field)"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        v-model="object[field.name]"
        :label="field.display_name"
        :required="field.required"
        :name="field.name"
        :ref="field.name"
        :value="object[field.name]"
        :helpText="field.help_text"
      />

      <base-page-path-selector
        v-if="['page_path_selector'].includes(field.type)"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        v-model="object[field.name]"
        :label="field.display_name"
        :required="field.required"
        :ref="field.name"
        :value="object[field.name]"
        :helpText="field.help_text"
        :siteId="object['site_id']"
        :editForm="formType == 'edit'"
        :field="field"
        @sync="syncValues"
      />

      <base-input-list
        v-if="field.type == 'input-list'"
        :value="object[field.name] ? JSON.parse(object[field.name]) : ['']"
        v-bind:key="field.name"
        v-bind:disabled="isDisabled"
        :label="field.display_name || $prettyLabels(field.name)"
        :readOnly="field.read_only"
        :formType="formType"
        :required="field.required"
        :ref="field.name"
        :field="field"
        :helpText="field.help_text"
        @sync="syncValues"
      />

      <sites-knows-about
        v-if="field.type == 'sites_knows_about'"
        v-bind:key="field.name"
        v-model="object[field.name]"
        :label="field.display_name"
      />
    </template>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import BaseInputList from '@atoms/fields/base-input-list.vue'
import BaseMultiselect from './base-multiselect'
import BaseMultiselectSorted from './base-multiselect-sorted.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseNumberInput from '@atoms/fields/base-number-input.vue'
import BasePagePathSelector from '@molecules/fields/base-page-path-selector.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import BaseTag from '@atoms/fields/base-tag.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseCheckboxList from '@atoms/fields/base-checkbox-list.vue'
import BaseColor from '@atoms/fields/base-color.vue'
import BaseListView from './base-list-view'
import BaseAverage from '@molecules/fields/base-average.vue'
import SitesKnowsAbout from '@atoms/fields/sites-knows-about.vue'
import BaseRichText from '@atoms/fields/base-rich-text'
import BaseRadio from '@atoms/fields/base-radio.vue'
import Media from '@molecules/fields/media.vue'
import ModelSelectModal from '@molecules/fields/model-select/base-model-select.vue'
import ExtraFieldValuesEdit from '@molecules/dms/extra-fields/extra-field-values-edit'
import BaseSelect from './base-select'
import BaseSelectVirtualList from './base-select-virtual-list'
import BaseNestedCheckbox from './base-nested-checkbox.vue'
import _ from 'lodash'

export default {
  components: {
    Media,
    BaseInputList,
    BaseMultiselect,
    BasePagePathSelector,
    BaseMultiselectSorted,
    BaseListView,
    BaseInput,
    BaseNumberInput,
    BaseDate,
    BaseRadio,
    BaseTextarea,
    BaseCheckbox,
    BaseTag,
    ModelSelectModal,
    BaseColor,
    ExtraFieldValuesEdit,
    BaseAverage,
    BaseCheckboxList,
    BaseSelect,
    BaseSelectVirtualList,
    BaseRichText,
    SitesKnowsAbout,
    BaseNestedCheckbox,
  },
  data() {
    return {
      isDisabled: false,
      isLoading: false,
      hiddenFields: [],
    }
  },
  computed: {
    visibleFields() {
      return this.formFields.filter((field) => {
        return this.canShow(field.name, field.only_edit, field.only_create)
      })
    },
  },
  unmounted() {
    if (this.closeOnDestroy) {
      var modalContainer = document.getElementById('modal')
      let index = null
      if (modalContainer) {
        let count = 0

        for (let item of modalContainer.children) {
          if (item.classList.contains('show')) {
            index = count
          }
          count++
        }
        index !== null && modalContainer.children[index].classList.remove('show')
      }
    }
  },
  props: {
    closeOnDestroy: {
      type: Boolean,
      default: () => true,
    },
    fieldClass: {
      type: String,
    },
    formType: {
      required: true,
      type: String,
    },
    object: {
      required: true,
    },
    filter: {
      type: Array,
      required: false,
    },
    formFields: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    // passed to base-model-select for site_id / market_id filters
    parameters: {
      type: Object,
      default: () => {},
    },
    siteSpecific: {
      type: Boolean,
      default: () => false,
    },
  },
  beforeMount() {
    this.isDisabled = this.disabled
  },
  methods: {
    handleBaseDateChange() {
      let fieldName = this.field && this.field.name
      this.$emit('valueChanged', this.object[fieldName])
    },
    readOnlyBasedOnPermission(permission) {
      return !this.hasPermission(permission)
    },
    clearInput(field) {
      if (field.clear_on_change) {
        delete this.object[field.clear_on_change]
      }
    },
    checkNotifyFields(field) {
      if (Array.isArray(field.notify_input_field)) {
        field.notify_input_field.forEach((inputFields) => {
          const targetField = this.formFields.find((item) => item.name === inputFields['field'])
          const triggerField = this.$refs[field.name][0]
          const triggerFieldOption = this.$refs[field.name][0].options.find((n) => n.id === triggerField.data)
          if (triggerFieldOption && Object.hasOwn(triggerFieldOption, inputFields['property'])) {
            this.$refs[targetField.name][0].data = triggerFieldOption[inputFields['property']]
          } else if (inputFields['default'] !== undefined) {
            this.$refs[targetField.name][0].data = inputFields['default']
          } else {
            this.$refs[targetField.name][0].data = null
          }
        })
      }
      if (Array.isArray(field.notify_fields) === false) {
        return false
      }
      if (field.conditionalDisable) {
        this.conditionalDisableCheck(field)
      }

      field.notify_fields.forEach((element) => {
        const formField = this.formFields.find((item) => item.name === element.field)

        if (formField === undefined) {
          return
        }

        this.syncValues(element.field, null)

        if (this.$refs[element.field] && this.$refs[element.field][0]) {
          if (this.$refs[element.field][0].type == 'list-one') {
            this.$refs[element.field][0].activeOptionLabel = ''
          } else if (this.$refs[element.field][0].type == 'list-many') {
            this.$refs[element.field][0].activeOptions = []
          } else {
            this.object[element.field] = null
          }

          if (this.object[field.name] && ['list-one', 'list-many'].includes(formField.type)) {
            this.$refs[element.field][0].getOptions()
          } else if (this.object[field.name] && formField.type === 'model_select') {
            this.$refs[element.field][0].clearAndEnable()
          }
        }
      })
    },
    conditionalLabelCheck(field) {
      if (field.show_if_message) {
        return this.object[field.show_if_message.field] == field.show_if_message.value
      }

      return false
    },
    conditionalCheck(field) {
      if (field.show_if) {
        if (Array.isArray(field.show_if)) {
          const validConditions = field.show_if.filter((element) => {
            return this.object[element.field] == element.value ? 1 : 0
          })
          return validConditions.length === field.show_if.length
        } else if (Array.isArray(field.show_if.value)) {
          return field.show_if.value.includes(this.object[field.show_if.field])
        } else {
          return this.object[field.show_if.field] === field.show_if.value
        }
      }

      if (field.show_if_not) {
        return this.object[field.show_if_not.field] !== field.show_if_not.value
      }

      return true
    },
    conditionalDisableCheck(field) {
      if (field && field.conditionalDisable) {
        const formField = this.formFields.find((item) => item.name == field.conditionalDisable.field)
        formField.read_only =
          this.object[field.name].length == 0 ||
          this.object[field.name].length > field.conditionalDisable.value

        formField.placeholder =
          this.object[field.name].length > field.conditionalDisable.value
            ? 'No options available'
            : `Select one or multiple options (${field.display_name.toLowerCase()})`
      }
    },

    getValue(field) {
      if (field.type === 'label') {
        return this.findInObject(field.value_key.split('.'), this.object)
      }

      if (field.type === 'date') {
        return field.value
      }

      // object is empty (create) and field has a default value
      if (Object.entries(this.object).length === 0 && field.create_defaults) {
        return field.create_defaults
      }

      // value should be retreive from a specific key
      if (field.value_key) {
        const valueKeys = field.value_key.split('.')

        const value = this.findInObject(valueKeys, this.object)
        const trackBy = field.trackBy ? field.trackBy : 'id'
        const selectLabel = field.selectLabel ? field.selectLabel : 'name'

        if (value instanceof Object) {
          this.object[field.name] = value[trackBy]
          return value
        }

        // build an object that is useable by the multi-select component
        let valueObject = {}
        valueObject[trackBy] = this.object[field.name]
        valueObject[selectLabel] = value

        // hacky solution to work with site_markets
        if (field.value_key === 'site_market.market.label') {
          valueObject = {
            id: this.object[field.name],
            market: {
              label: value,
            },
          }
        }

        return valueObject
      }

      return this.object[field.name]
    },
    datePickerConfig(field) {
      if (field.mode) {
        return {
          defaultDate: field.date,
          mode: field.mode,
        }
      }

      if (field.config) {
        return field.config
      }

      return {
        defaultDate: field.date,
      }
    },
    modelSelected(value, value_label, field) {
      this.object[field.name] = value instanceof Array ? value.join(',') : value
      this.object[field.value_label] =
        value_label instanceof Array ? JSON.stringify(value_label) : value_label
      this.$forceUpdate()
    },
    syncValues(field, value, key = 'id', valueLabel = false) {
      if (value) {
        if (field === 'same_as') {
          this.object[field] = value

          this.$emit('valueChanged', this.object[field.name])
        } else if (key === 'tag-input') {
          // one item (list-one)
          this.object[field] = value
        } else if (Array.isArray(value)) {
          this.object[field] = value.map((item) => item[key])
        } else if (_.isObject(value)) {
          // one item (list-one)
          this.object[field] = value[key]
        } else {
          this.object[field] = value
        }
      } else if (value === null) {
        // this is used for removing image from dropzone, this nulls the logo_field_id
        this.object[field] = null
      }
      if (valueLabel !== false) {
        this.object.value_label = valueLabel
      }
    },
    process(object) {
      this.isLoading = false
      return new Promise((resolve) => resolve(object))
    },
    canShow(field, editOnly, createOnly) {
      if (this.formType === 'edit' && createOnly) {
        return false
      }
      if (this.formType !== 'edit' && editOnly) {
        return false
      }
      return _.indexOf(this.filter, field) === -1
    },
    disableEdit(disable) {
      this.isDisabled = disable

      Object.keys(this.$refs).forEach((key) => {
        if (Object.hasOwn(this.$refs[key][0], 'isDisabled')) {
          this.$refs[key][0].isDisabled = disable
        }
      })
    },
    notifyFields(ref) {
      this.$refs[ref][0] && this.$refs[ref][0] && this.$refs[ref][0].clearAndEnable()
    },
    notifyExtraFields(method) {
      this.$emit('notifyExtraFields', method)
    },
    async checkInputChange(value, field) {
      if (field === undefined) {
        return
      }
      if (field.type === 'slug') {
        this.object[field.name] = this.slugify(value)
      }

      if (Object.hasOwn(field, 'slug_field')) {
        const slugTarget = this.formFields.find((item) => item.name === field.slug_field)
        if (slugTarget && (this.formType !== 'edit' || !slugTarget.read_only)) {
          this.object[field.slug_field] = this.slugify(value)
        }
        if (Object.hasOwn(field, 'prefix') && value) {
          this.object[field.slug_field] = this.slugify(field.prefix + '_' + value)
        }
      }

      if (field.clear_on_change) {
        this.object[field.clear_on_change] = null
      }

      this.$refs[field.name][0].$refs.input.value = this.object[field.name]
      this.$emit('valueChanged', this.object[field.name])
    },
    editVariableField(field) {
      if (
        Object.hasOwn(field, 'affiliate_operator_id') &&
        Object.hasOwn(field, 'extra_value_id') &&
        !Object.hasOwn(field, 'variable_field_object')
      ) {
        // this is coming from the edit of affiliate operator
        this.$refs['variable_modal_' + field.id][0].show(field.affiliate_operator_id, field.extra_value_id)
      } else if (
        Object.hasOwn(field, 'affiliate_operator_id') &&
        Object.hasOwn(field, 'extra_value_id') &&
        Object.hasOwn(field, 'variable_field_object')
      ) {
        // this is coming from the edit of affiliate operator, after user has already tried editing this variable
        this.$refs['variable_modal_' + field.id][0].showVariableFieldValue(field.variable_field_object)
      } else if (Object.hasOwn(field, 'affiliate_id')) {
        // this coming from a new affiliate operator
        // let's check if we have an operator
        if (Object.hasOwn(this.$parent.$parent.object, 'operator_id')) {
          if (
            Object.hasOwn(this.$parent.$parent.object, 'market_id') &&
            typeof this.$parent.$parent.object.market_id !== 'undefined' &&
            this.$parent.$parent.object.market_id !== null
          ) {
            if (
              Object.hasOwn(this.$parent.$parent.object, 'type') &&
              typeof this.$parent.$parent.object.type !== 'undefined' &&
              this.$parent.$parent.object.type !== null
            ) {
              if (!Object.hasOwn(field, 'variable_field_object')) {
                this.$refs['variable_modal_' + field.id][0].showVariableField(
                  field.id,
                  this.$parent.$parent.object.operator_id,
                  this.$parent.$parent.object.market_id,
                  this.$parent.$parent.object.type
                )
              } else {
                this.$refs['variable_modal_' + field.id][0].showVariableFieldValue(
                  field.variable_field_object
                )
              }
            } else {
              this.showErrorMessage('Make sure you have a type selected')
            }
          } else {
            this.showErrorMessage('Make sure you have selected a market first')
          }
        } else {
          this.showErrorMessage('Make sure you have an operator selected')
        }
      }
    },
    storeVariableField(variableObject, field) {
      field.variable_field_object = variableObject
      this.object[field.name] = variableObject.value
    },
    hideOption(toHide, field, value) {
      let removedVisibleField = false
      this.visibleFields.some((visible, index) => {
        if (visible.name == toHide.hide.field) {
          if (Object.hasOwn(toHide.hide, 'option')) {
            // for select2 options
            if (Object.hasOwn(visible, 'options')) {
              // this is infact a select2 option
              // we need to hide an option
              if (value == toHide.selected) {
                // the option is the right one we can hide it
                visible.options.some((option, key) => {
                  if (option == toHide.hide.option) {
                    this.visibleFields[index].options.splice(key, 1)
                    return true
                  }
                })

                removedVisibleField = true
                this.hiddenFields[field] = [{ value: value, toHide, field: visible }]
                return true
              }
            }
          }
        }
      })

      if (!removedVisibleField) {
        // we need to include this option again
        this.visibleFields.some((visible, index) => {
          if (visible.name == toHide.hide.field) {
            if (Object.hasOwn(toHide.hide, 'option')) {
              // for select2 options
              if (Object.hasOwn(visible, 'options')) {
                // this is infact a select2 option
                // we need to hide an option
                this.visibleFields[index].options.push(toHide.hide.option)
                delete this.hiddenFields[field]
                return true
              }
            }
          }
        })
      }
    },
  },
}
</script>
