<template>
  <div class="sidebar-secondary">
    <div class="sidebar-heading">
      <div class="sidebar-brand">
        <router-link :to="{ path: '/' }" class="d-flex align-items-center">
          <div class="" style="line-height: 20px">
            <div>Hercules</div>
            <div class="text-muted font-size-sm">
              {{ $store.state.app.environmentName }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="sidebar-body new-menu pl-0">
      <input
        ref="search-menu"
        type="text"
        class="from form-control"
        placeholder="Search menu"
        v-model="search"
        @keyup.enter="searchSubmit"
      />
      <div class="sidebar-body-content h-full">
        <template v-if="selectedItem === 'Home' && !search">
          <h4 class="sidebar-title" :class="{ 'custom-bottom-border': !recentMenuItems.length }">
            {{ !recentMenuItems.length ? 'No recently viewed items' : 'Recent items' }}
          </h4>
          <div class="filtered-items" v-for="(rec, rIndex) in recentMenuItems" :key="'a' + rIndex">
            <div class="list-group">
              <router-link
                :to="{ path: rec.to }"
                v-bind:class="{ 'text-blue-75': bestMatch && bestMatch.to === rec.to }"
                class="d-flex justify-content-between list-group-item"
                style="height: 40px"
              >
                <span class="title">{{ rec.name }}</span>
                <span @click.prevent="favoriteItem(rec)" class="custom-star-icon">
                  <div v-if="favorites.some((n) => n.to === rec.to)">&#9733;</div>
                  <div class="star-icon" v-else>&#9734;</div>
                </span>
              </router-link>
            </div>
          </div>
          <h4 class="sidebar-title" :class="{ 'custom-bottom-border': !favorites.length }">
            {{ !favorites.length ? 'No favorite items' : 'Favorites' }}
          </h4>
          <div class="filtered-items" v-for="(fav, fIndex) in favoriteMenuItems" :key="fIndex">
            <div class="list-group">
              <router-link
                :to="{ path: fav.to }"
                v-bind:class="{ 'text-blue-75': bestMatch && bestMatch.to === fav.to }"
                class="d-flex justify-content-between list-group-item"
                style="height: 40px"
                @click="handleMenuCollapse"
              >
                <span class="title">{{ fav.name }}</span>
                <span @click.prevent="favoriteItem(fav)" class="custom-star-icon">
                  <div v-if="favorites.some((n) => n.to === fav.to)">&#9733;</div>
                  <div class="star-icon" v-else>&#9734;</div>
                </span>
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <h4 class="sidebar-title" v-if="!search">{{ selectedItem }}</h4>
          <div class="filtered-items" v-for="(child, childIndex) in filteredChildren" :key="childIndex">
            <submenuItems
              :category="child.children"
              :title="child.children.filter((n) => allowedToShow(n)).length ? child.name : ''"
              v-if="search"
              :favorites="favorites"
            />

            <submenuItems
              v-else
              :title="
                filteredChildren[childIndex + 1] && !filteredChildren[childIndex + 1].subHeader
                  ? child.subHeader
                  : ''
              "
              :category="child"
              :favorites="favorites"
              @click="handleMenuCollapse"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import submenuItems from './sub-menu-filtered-items.vue'
export default {
  components: { submenuItems },
  computed: {
    favorites() {
      return this.$store.state.sidebar.structure.find((n) => n.name === 'Home').favorites.children || []
    },
    recentMenuItems() {
      return this.$store.state.sidebar.structure[1].recentItems.children.filter((item) =>
        this.allowedToShow(item)
      )
    },
    favoriteMenuItems() {
      return this.$store.state.sidebar.structure[1].favorites.children.filter((item) =>
        this.allowedToShow(item)
      )
    },
    search: {
      get() {
        return this.$store.state.sidebar.search
      },
      set(value) {
        this.$store.commit('sidebar/setSearch', value)
      },
    },
    bestMatch() {
      return this.$store.getters['sidebar/bestMatch']
    },
    filteredChildren() {
      if (!this.$store.state.sidebar.selectedSidebarItem) {
        return []
      }

      if (this.$store.state.sidebar.search && this.$store.state.sidebar.search !== '') {
        // remove Home and Filter Menu from the structure
        let struct = this.$store.state.sidebar.structure.slice(2)
        let foundItems = struct.map((n) => {
          return {
            name: n.name,
            children: n.children.filter(
              (child) =>
                child.name?.toLowerCase().includes(this.$store.state.sidebar.search?.toLowerCase()) &&
                this.allowedToShow(child)
            ),
          }
        })

        foundItems = foundItems.filter((n) => n.children.length > 0)
        return foundItems.flat(1)
      }

      const selectedItem = this.$store.state.sidebar.structure.find(
        (n) => n.name === this.$store.state.sidebar.selectedSidebarItem
      )

      return selectedItem ? selectedItem.children.filter((child) => this.allowedToShow(child)) : []
    },

    selectedItem() {
      return this.$store.state.sidebar.selectedSidebarItem
    },
  },
  methods: {
    handleMenuCollapse() {
      let getMenuToggleState = JSON.parse(localStorage.getItem('firstToggleInteraction'))
      if (!getMenuToggleState) {
        this.$emit('collapseSubmenu', false)
      }
    },
    allowedToShow(child) {
      return this.hasPermission(child.permission)
    },
    searchSubmit() {
      if (this.bestMatch) {
        this.$router.push(this.bestMatch.to)
        this.$store.commit('sidebar/setSearch', '')
        this.$refs['search-menu'].blur()
      }
    },
    favoriteItem(value) {
      this.$store.commit('sidebar/setFavoriteItem', value)
    },
  },
}
</script>
