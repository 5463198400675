<script>
import TableModalForm from '@organisms/table/table-modal-form.vue'

export default {
  components: { TableModalForm },
  extends: TableModalForm,
  data() {
    return {
      formType: 'create',
    }
  },
  props: {
    showRemove: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    async show() {
      this.clear()
      if (this.notifyExtraFieldIdCreate) {
        this.notifyExtraFields({ param: this.notifyExtraFieldIdCreate })
      }
      this.showModal()
    },
    filterInvalidValues(obj) {
      const filteredObj = {}
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          const value = obj[key]
          // Only include keys where value is not null, is a string, or is a valid number
          if (value !== null && (typeof value === 'string' || !Number.isNaN(value))) {
            // Only include keys that do not exist in this.object
            if (!(key in this.object)) {
              filteredObj[key] = value
            }
          }
        }
      }
      return filteredObj
    },

    async submit() {
      this.$refs.form.isLoading = true
      const validDefaults = this.filterInvalidValues(this.createDefaults)
      this.object = Object.assign(this.object, validDefaults)
      return await this.serviceLoader()
        .then((service) => {
          return service.default.post(this.object)
        })
        .then((response) => {
          if (this.$refs.form) {
            this.$refs.form.isLoading = false
          }
          return this.handleCreateResponse(response)
        })
        .catch(() => this.showUnknownErrorMessage)
    },
    handleCreateResponse(response) {
      return new Promise((resolve) => {
        let id = null

        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          if (this.structure.config.msgProp) {
            const msg = this.structure.config.msg

            this.showSuccessMessage(`${response.data.result[this.structure.config.msgProp]} ${msg}`)
          } else {
            this.showSuccessMessage(`${this.structure.config.name} created`)
          }

          this.$store.commit('dynamicForm/setFormSubmitted', true)
          this.close()
          this.$nextTick(() => {
            if (this.postCreateNavigationRoute) {
              this.$router.push(`${this.postCreateNavigationRoute}/${response.data.result.id}`)
            } else {
              this.$emit('refresh')
            }
            if (response.data && response.data.result) {
              id = response.data.result.id
            }

            resolve(id)
          })
        }
      })
    },
  },
}
</script>
