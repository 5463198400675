import Api from './Api'

export default {
  getOne(id) {
    return Api().get(`hercules/globals/software/${id}`)
  },
  post(data) {
    return Api().post(`hercules/globals/software`, data)
  },
  update(data) {
    return Api().put(`hercules/globals/software/${data.id}`, data)
  },
  remove(data) {
    return Api().delete(`hercules/globals/software/${data.id}`)
  }
}
