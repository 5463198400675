<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">Generate Site General Data Report</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <base-select
              label="Site"
              option-label-key="website"
              path="hercules/globals/sites?orderBy=name&ascending=1"
              v-model="site"
              :required="true"
              trackBy="id"
              @update:modelValue="changeSiteId()"
            />
          </div>
        </div>
        <div class="row">
          <base-multiselect
            ref="marketSelect"
            :multiple="true"
            field="markets"
            label="Markets"
            selectLabel="label"
            :required="true"
            trackByOption="id"
            :path="`hercules/operators/affiliate-markets-data?affiliate_id=${site}&with=market,linked_group&label=1`"
            :value="markets"
            class="col-10 pr-2 pl-2 mb-0"
            helpText="Showing markets coming from DMS > Affiliate site settings > Markets data"
            @sync="syncMarket"
          />
        </div>
        <div class="col-10">
          <div class="row">
            <base-select
              label="Data type"
              :custom-options="['operator', 'games', 'payment_methods']"
              option-label-key="data_type"
              optionLabelKey="data_type"
              v-model="data_type"
              @update:modelValue="changeDataType()"
            />
          </div>
        </div>
        <div class="col-10">
          <div class="row">
            <base-select
              label="Data section"
              :custom-options="getDateOptions(this.data_type)"
              v-model="section"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button :class="{ loading: loading }" class="btn btn-primary icon-btn" @click="exportCsv">
          <i class="uil uil-file-export"></i>Export to CSV
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'
import BaseMultiselect from '@/components/fields/base-multiselect'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      site: null,
      markets: [],
      loading: false,
      data_type: null,
      section: null,
    }
  },
  components: { BaseSelect, BaseMultiselect },
  methods: {
    syncMarket(_field, value, key) {
      let newValue = null
      if (value && Array.isArray(value)) {
        newValue = value.map((item) => item[key])
      }
      this.markets = newValue
    },
    exportCsv() {
      this.loading = true
      const filename = `${this.data_type}-${this.section}-site-data-report-${moment().format(
        'YYYY-MM-DD-HHmm'
      )}`
      let params = {
        site: this.site,
      }
      if (this.markets) {
        params.markets = this.markets
      }
      if (this.data_type) {
        params.data_type = this.data_type
      }
      if (this.section) {
        params.section = this.section
      }

      this.$http
        .get(`hercules/operators/generate-report`, {
          params,
        })
        .then((response) => {
          const items = response.data.result
          if (items.length === 0) {
            this.showErrorMessages('No results for selected fields')
            this.loading = false
            return
          }

          items.forEach((item) => {
            if (Object.hasOwn(item, 'Site Data Currencies Name')) {
              item['Site Data Currencies Name'] ??= ''
            }
            if (Object.hasOwn(item, 'General Currencies Name')) {
              item['General Currencies Name'] ??= ''
            }
            if (Object.hasOwn(item, 'Market Data Currencies Name')) {
              item['Market Data Currencies Name'] ??= ''
            }
            if (Object.hasOwn(item, 'General Data Provider Name')) {
              item['General Data Provider Name'] ??= ''
            }
            if (Object.hasOwn(item, 'Market Data Provider Name')) {
              item['Market Data Provider Name'] ??= ''
            }
            if (Object.hasOwn(item, 'Provider Short Name')) {
              item['Provider Short Name'] ??= ''
            }
            if (Object.hasOwn(item, 'Provider Games Count')) {
              item['Provider Games Count'] ??= ''
            }
          })

          const csvExporter = new ExportToCsv({
            useKeysAsHeaders: true,
            filename: filename,
          })
          csvExporter.generateCsv(items)
          this.loading = false
        })
        .catch(() => {
          this.showErrorMessage('Failed to generate report')
          this.loading = false
        })
    },
    changeSiteId() {
      this.$nextTick(() => {
        this.$refs.marketSelect.getOptions()
      })
    },
    changeDataType() {
      this.section = null
    },

    getDateOptions(data_type) {
      if (data_type == 'games') {
        return [
          { label: 'Game Categories', id: 'game_categories' },
          { label: 'Currencies', id: 'currencies' },
        ]
      }

      return [
        { label: 'Selling Points', id: 'selling_points' },
        { label: 'Payment Methods', id: 'payment_methods' },
        { label: 'Providers', id: 'providers' },
        { label: 'Operator Licenses', id: 'operator_licenses' },
        { label: 'Support Types', id: 'support_types' },
        { label: 'Game Categories', id: 'game_categories' },
        { label: 'Currencies', id: 'currencies' },
        { label: 'Countries', id: 'countries' },
        { label: 'Gambling companies', id: 'gambling_companies' },
        { label: 'Affiliate Programs', id: 'affiliate_programs' },
      ]
    },
  },
}
</script>
