import Settings from '@pages/admin/settings.vue'
import Roles from '@pages/admin/roles/roles.vue'
import RoleEdit from '@pages/admin/roles/role-edit.vue'
import Permissions from '@pages/admin/permissions.vue'
import AffiliatePlatformPostbackGenerator from '@pages/admin/affiliate-platforms-postbacks-generator.vue'
import TrackingDetails from '@pages/admin/tracking-details.vue'
import Users from '@pages/admin/users.vue'

export default [
  {
    path: '/settings',
    component: Settings,
    meta: {
      parent: 'Admin',
      auth: true,
      permission: 'view.backoffice/settings',
      name: 'Settings',
    },
  },
  {
    path: '/users',
    component: Users,
    name: 'Users',
    meta: {
      parent: 'Admin',
      auth: true,
      permission: 'view.backoffice/users',
      name: 'Users',
    },
  },
  {
    path: '/roles/:id',
    component: RoleEdit,
    name: 'Role edit',
    meta: {
      parent: 'Admin',
      auth: true,
      permission: 'view.backoffice/roles',
      name: 'Role edit',
    },
  },
  {
    path: '/roles',
    component: Roles,
    name: 'Roles',
    meta: {
      parent: 'Admin',
      auth: true,
      permission: 'view.backoffice/roles',
      name: 'Roles',
    },
  },
  {
    path: '/permissions',
    component: Permissions,
    meta: {
      parent: 'Admin',
      auth: true,
      permission: 'view.backoffice/permissions',
      name: 'Permissions',
    },
  },
  {
    path: '/affiliate-platform-postback-generator',
    component: AffiliatePlatformPostbackGenerator,
    meta: {
      parent: 'Admin',
      name: 'Postbacks generator',
    },
  },
  {
    path: '/tracking-details',
    component: TrackingDetails,
    meta: {
      parent: 'Admin',
      name: 'Tracking Details',
    },
  },
]
