export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name',
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        read_only: false,
      },
      {
        name: 'url',
        required: false,
        display_name: 'URL',
        type: 'text',
      },
      {
        name: 'logo_asset_id',
        required: false,
        display_name: 'Logo',
        help_text: 'Standardised logo',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.cryptos/manage-standardised-logos',
        standardised: true,
      },
      {
        name: 'affiliate_link',
        required: false,
        display_name: 'Broker affiliate link',
        type: 'text',
      },
      {
        name: 'min_deposit',
        required: false,
        display_name: 'Min deposit',
        type: 'text',
      },
      {
        name: 'trading_fees',
        search_url: 'hercules/cryptos/trading-fees',
        display_name: 'Trading fees',
        display_field: 'name',
        selectLabel: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        required: false,
      },
      {
        name: 'regulation',
        required: false,
        display_name: 'Regulation',
        type: 'text',
      },
      {
        name: 'has_mobile_app',
        required: false,
        display_name: 'Has mobile app?',
        type: 'checkbox',
      },
      {
        name: 'currencies',
        required: false,
        display_name: 'Coins',
        type: 'list-many',
        search_url: 'hercules/globals/currencies?crypto=1&with_no_assets=1',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'deposit_methods',
        search_url: 'hercules/globals/deposit-methods?with_no_assets=1',
        required: false,
        display_name: 'Payment Options',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'user_amount',
        required: false,
        display_name: 'User Amount',
        type: 'text',
      },
      {
        name: 'risk_warning',
        required: false,
        display_name: 'Risk Warning',
        type: 'text',
      },
      {
        name: 'selling_points',
        required: false,
        display_name: 'Selling Points',
        type: 'list-many',
        search_url: 'hercules/globals/selling-points',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
    ]
  },
  getConfig() {
    return {
      url: 'cryptos/brokers',
      name: 'Crypto Brokers',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
