import { Node, mergeAttributes } from '@tiptap/core'
import { NodeSelection } from '@tiptap/pm/state'
import { ColumnSelection } from '../multi-columns/column-selection'
import { buildNColumns, buildPanelBlock, findParentNodeClosestToPos } from '../multi-columns/utils'
// import { ColumnIcon } from './column-icon'
import { Column } from '../multi-columns/column'

export const PanelBlock = Node.create({
  name: 'panelBlock',
  group: 'block',
  content: 'column{1,}',
  isolating: true,
  selectable: true,

  addOptions() {
    return {
      nestedColumns: false,
      columnType: Column,
    }
  },

  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: (element) => element.getAttribute('id'),
        renderHTML: (attributes) => {
          const attr = {}
          if (attributes.id) {
            attr.id = attributes.id
          }
          return attr
        },
      },
    }
  },

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }]
  },

  renderHTML({ node, HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, {
      'data-type': this.name,
      'data-type-panel': node.attrs.id,
      class: node.attrs.id + '-' + 'panel',
    })
    return ['div', attrs, 0]
  },

  addCommands() {
    const unsetPanelBlock =
      () =>
      ({ tr, dispatch }) => {
        try {
          if (!dispatch) {
            return
          }

          const pos = tr.selection.$from
          const where = ({ node }) => {
            if (!this.options.nestedColumns && node.type === this.type) {
              return true
            }
            return node.type === this.type
          }
          const firstAncestor = findParentNodeClosestToPos(pos, where)
          if (firstAncestor === undefined) {
            return
          }

          const resolvedPos = tr.doc.resolve(firstAncestor.pos)
          const sel = new NodeSelection(resolvedPos)

          // Set the selection to the panel block
          tr = tr.setSelection(sel)

          // Delete the selected panel block
          tr = tr.deleteSelection()

          return dispatch(tr)
        } catch (error) {
          console.error(error)
        }
      }

    const setPanelBlock =
      (id) =>
      ({ tr, state, dispatch }) => {
        try {
          const { doc, selection } = tr

          if (!dispatch) {
            console.log('no dispatch')
            return
          }

          // Create a new column selection from the current selection
          const sel = new ColumnSelection(selection)
          sel.expandSelection(doc)

          const { openStart, openEnd } = sel.content()
          if (openStart !== openEnd) {
            console.warn('failed depth check')
            return
          }

          const columns = buildNColumns(1)

          // Build the panel block with the given ID and columns
          const panelBlock = buildPanelBlock({
            content: columns,
            attrs: { id },
          })

          const newNode = doc.type.schema.nodeFromJSON(panelBlock)
          if (newNode === null) {
            return
          }

          // Determine the parent node
          const parent = sel.$anchor.parent.type
          const canAcceptPanelBlock = (par) => {
            if (!par.contentMatch.matchType(newNode.type)) {
              return false
            }

            if (!this.options.nestedColumns && par.name === Column.name) {
              return false
            }

            return true
          }

          if (!canAcceptPanelBlock(parent)) {
            console.warn('content not allowed')
            return
          }

          // Determine the position to insert the panel block
          let pos
          if (doc.content.size === 0) {
            // If the document is empty, insert at the top
            pos = 0
          } else if (sel.$anchor.depth === 0 && sel.$anchor.parentOffset === 0) {
            // If the cursor is at the top-level node and at the beginning, insert at position 0
            pos = 0
          } else if (sel.$anchor.parentOffset === 0) {
            // If the cursor is at the beginning of a block, insert above the current block
            pos = sel.$anchor.before()
          } else {
            // Otherwise, insert at the current position
            pos = sel.$anchor.pos
          }

          tr = tr.insert(pos, newNode)

          // Dispatch the transaction
          dispatch(tr)
        } catch (error) {
          console.error(error)
        }
      }

    return {
      unsetPanelBlock,
      setPanelBlock,
    }
  },
})
