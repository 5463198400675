<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/games` }"
            data-tooltip="Back to games"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ game.name }}</span>
          <span class="text-muted mr-2">{{ game.provider }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(game.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/games/${game.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link
        tag="li"
        v-if="hasPermission('view.games/game-screenshots')"
        :to="{ path: `/games/${game.id}/screenshots` }"
        exact
      >
        <a>Screenshots</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/games/${game.id}/markets` }" exact>
        <a>Markets Data</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/games/${game.id}/sites` }" exact>
        <a>Sites Data</a>
      </router-link>
      <router-link
        tag="li"
        v-if="hasPermission('view.games/game-symbols')"
        :to="{ path: `/games/${game.id}/symbols` }"
        exact
      >
        <a>Symbols</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import GamesService from '@services/GamesService'

export default {
  data() {
    return {
      title: '',
    }
  },
  async created() {
    if (this.isIdMismatch) {
      await this.getGame()
      console.log(this.game.id, 'this game id')
    } else {
      this.$store.commit('editPage/setIsLoading', false)
      this.title = this.game?.name
    }
  },
  computed: {
    isIdMismatch() {
      return this.game?.id !== Number(this.$route.params['id'])
    },
    game() {
      return { ...this.$store.state.editPage.model }
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getGame() {
      await GamesService.getOne(this.$route.params['id'], { with: 'software' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Game not found') >= 0) {
                this.$router.push('/games')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = response.data.result[0].name

            if (this.$store.hasModule('data-table')) {
              this.$store.commit('data-table/setTitle', response.data.result[0].name)
              this.$store.commit(
                'data-table/setStandardisedLogo',
                response.data.result[0] && response.data.result[0].standardised_logo_asset_id
              )
            }
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
    this.$store.commit('data-table/setStandardisedLogo', null)
    this.$store.commit('data-table/setTitle', '')
  },
}
</script>
