<template>
  <span :class="statusClass">
    {{ statusName }}
  </span>
</template>

<script>
export default {
  props: {
    action: String,
  },
  computed: {
    statusName() {
      return this.action
    },
    statusClass() {
      return `logs-status logs-status-${this.action} text-truncate`
    },
  },
}
</script>
