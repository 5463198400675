export default {
  getForm() {
    return [
      {
        name: 'source_menu_id',
        type: 'hidden',
      },
      {
        name: 'source_market_id',
        type: 'hidden',
      },
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'name',
        only_create: true,
      },
      {
        name: 'title',
        required: true,
        display_name: 'Title',
        type: 'text',
        slug_field: 'short_code',
      },
      {
        name: 'short_code',
        required: true,
        display_name: 'Short code',
        type: 'slug',
        disabled: true,
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        selectLabel: 'label',
        type: 'list-one',
        value_key: 'market.label',
        country_flag: 'country_code',
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/menus',
      name: 'Menu',
      pagination: true,
    }
  },
  getStructure(menuId) {
    return {
      list: this.getForm(menuId),
      config: this.getConfig(),
    }
  },
}
