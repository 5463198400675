<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg2">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="nav-wrap-modal">
          <div
            class="modal-nav"
            @click="setActiveTab(0)"
            :style="activeTab == 0 ? 'margin-bottom: -1px' : ''"
          >
            Desktop
          </div>
          <div
            class="modal-nav"
            @click="setActiveTab(1)"
            :style="activeTab == 1 ? 'margin-bottom: -1px' : ''"
          >
            Mobile
          </div>
        </div>
        <div class="modal-body border p-0 overflow-auto d-flex justify-content-center" style="height: 500px">
          <div v-if="loading">
            <span>Loading ...</span>
          </div>

          <div v-if="showError">
            <span>Failed to load preview</span>
          </div>
          <iframe
            v-show="(!loading && activeTab == 0) || activeTab == 1"
            :src="iframeUrl"
            frameborder="0"
            ref="iframeIdDesktop"
            scrolling="no"
            @load="load"
            @error="loadError"
            :height="iframeHeightDesktop ? iframeHeightDesktop : '600'"
            :width="widthDesktop"
            :style="activeTab == 1 && 'visibility: hidden; position: absolute; left: -9999px'"
          ></iframe>
          <!-- Mobile Iframe loaded on mount since we need access to mobile height even when desktop iframe is on window -->
          <iframe
            v-show="(!loading && activeTab == 0) || activeTab == 1"
            :src="iframeUrl"
            frameborder="0"
            ref="iframeIdMobile"
            scrolling="no"
            @load="load"
            @error="loadError"
            :height="iframeHeightMobile ? iframeHeightMobile : '600'"
            :width="widthMobile"
            :style="activeTab == 0 && 'visibility: hidden; position: absolute; left: -9999px'"
          ></iframe>
        </div>
        <div class="modal-footer py-2">
          <button class="btn btn-secondary" v-if="iframeHeightDesktop && activeTab == 0">
            Height: {{ iframeHeightDesktop }}
          </button>
          <button class="btn btn-secondary" v-else-if="iframeHeightDesktop && activeTab == 1">
            Height: {{ iframeHeightMobile }}
          </button>
          <button class="btn btn-secondary" @click="copyIframeUrl">Copy iframe URL</button>
          <button class="btn btn-primary" @click="openInNewTab">Open in new tab</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Preview',
    },
    siteTheme: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      iframeUrl: '',
      loading: true,
      showError: false,
      activeTab: 0,
      iframeHeightDesktop: 0,
      iframeHeightMobile: 0,
      heightDesktop: 0,
      heightMobile: 0,
      widthDesktop: 1024,
      widthMobile: 375,
    }
  },
  mounted() {
    window.addEventListener('message', this.handlePostMessage)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handlePostMessage)
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value
      if (value == 0) {
        this.$refs.iframeIdDesktop.width = `${this.widthDesktop}`
      } else {
        this.$refs.iframeIdMobile.width = `${this.widthMobile}`
      }
      this.reloadIframe()
    },
    handlePostMessage(event) {
      if (event.data && event.data.alturaIframe) {
        if (event.source === this.$refs.iframeIdDesktop.contentWindow) {
          this.iframeHeightDesktop = event.data.alturaIframe
          this.$nextTick(() => {
            this.heightDesktop = this.$refs.iframeIdDesktop?.getAttribute('height')
            this.heightMobile = this.$refs.iframeIdMobile?.getAttribute('height')
          })
        } else {
          this.iframeHeightMobile = event.data.alturaIframe
          this.$nextTick(() => {
            this.heightDesktop = this.$refs.iframeIdDesktop?.getAttribute('height')
            this.heightMobile = this.$refs.iframeIdMobile?.getAttribute('height')
          })
        }
      }
    },
    reloadIframe() {
      this.loading = true
      let currentSrc = this.iframeUrl
      this.iframeUrl = '' // Clear src to force reload
      this.$nextTick(() => {
        this.iframeUrl = currentSrc
      })
    },
    loadError() {
      this.loading = false
      this.showError = true
    },
    load() {
      this.loading = false
      this.showError = false
    },
    show(url, siteId) {
      // check if site is thesun
      if (siteId == 182) {
        this.widthDesktop = 670
        this.widthMobile = 394
      }
      this.iframeUrl = url
      this.$refs.modal.classList.add('show')
      this.load()
    },
    close() {
      this.$refs.modal.classList.remove('show')
      this.$emit('closeModal')
    },
    openInNewTab() {
      window.open(this.iframeUrl, '_blank')
    },
    copyIframeUrl() {
      let iframeUrl = this.iframeUrl
      if (this.siteTheme !== 'Partnerships') {
        iframeUrl += `&height=[${this.heightDesktop},${this.heightMobile}]`
      }
      navigator.clipboard.writeText(iframeUrl)
      this.showSuccessMessage('Iframe URL copied to clipboard')
    },
  },
}
</script>

<style lang="scss">
.bottomshadow {
  box-shadow: inset 0px -10px 15px -15px grey;
}

.nav-wrap-modal {
  display: flex;
  margin-left: 2rem;
  .modal-nav {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: 1px solid #ced0dd;
    border-radius: 0.25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    background: white;
    z-index: 1;
    &:first-child {
      border-right: 0;
    }
  }
}
</style>
