const state = () => ({
  visibility: false,
  siteSpecific: false,
  selectedAsset: null,
  activeMediaLibId: null,
  siteId: null,
  marketId: null,
  standardised: false,
  loadedAssetsArray: [],
})

const getters = {}

const actions = {
  setVisibility({ commit }, value) {
    commit('setVisibility', value)
  },
  setActiveMediaLib({ commit }, value) {
    commit('setActiveMediaLib', value)
  },
  setSiteAndMarket({ commit }, data) {
    commit('setSiteAndMarket', data)
  },
  setSite({ commit }, value) {
    commit('setSite', value)
  },
  setSiteSpecific({ commit }, value) {
    commit('setSiteSpecific', value)
  },
  setSelectedAsset({ commit }, value) {
    commit('setSelectedAsset', value)
  },
  setStandardised({ commit }, value) {
    commit('setStandardised', value)
  },
  setLoadedAssetsArray({ commit }, value) {
    commit('setLoadedAssetsArray', value)
  },
}

const mutations = {
  setVisibility(state, value) {
    state.visibility = value
  },
  setSiteSpecific(state, value) {
    state.siteSpecific = value
  },
  setActiveMediaLib(state, value) {
    state.activeMediaLibId = value.uid
    state.selectedAsset = value.asset
  },
  setSelectedAsset(state, value) {
    state.selectedAsset = value
  },
  setSite(state, value) {
    state.siteId = value
  },
  setSiteAndMarket(state, data) {
    state.siteId = data.site_id
    state.marketId = data.market_id
  },
  setStandardised(state, value) {
    state.standardised = value
  },
  setLoadedAssetsArray(state, value) {
    if (!value) {
      state.loadedAssetsArray = []
      return
    }
    const findIndex = state.loadedAssetsArray.findIndex((media) => media.id === value.id)
    if (findIndex === -1) {
      state.loadedAssetsArray.push(value)
    } else {
      state.loadedAssetsArray.splice(findIndex, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
