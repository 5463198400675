<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    id="toplist-edit-platform-domain"
    v-click-outside="close"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pb-4 d-flex flex-column position-relative">
          <div class="d-flex">
            <h5 class="modal-title">Edit platform domain</h5>
            <button class="close" v-on:click="close"></button>
          </div>
        </div>
        <div class="modal-body" v-if="domain">
          <base-input v-model="domain.domain" label="Domain" :readonly="true" />
          <base-select
            label="Affiliate Platform"
            v-model="domain.platform_id"
            optionLabelKey="name"
            path="hercules/operators/affiliate-platforms?ascending=1&orderBy=name"
          />
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <a class="btn btn-primary ml-3" :class="{ loading: isLoading }" @click="update">Update</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemRow from './item-row-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { ItemRow, BaseInput, BaseCheckbox, BaseSelect },
  data() {
    return {
      domain: null,
      row: null,
      isLoading: false,
    }
  },
  props: {
    index: {
      required: true,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    async show(row, link) {
      if (!link) {
        return
      }
      this.row = row
      const parsedUrl = new URL(link)
      this.domain = { domain: parsedUrl.hostname, platform_id: null }
      this.$refs.modal.classList.add('show')
    },
    async update() {
      this.isLoading = true
      await this.$http
        .post(`hercules/operators/affiliate-platform-domains`, this.domain)
        .then((response) => {
          if (response.data.success) {
            this.$emit('refresh', this.index, response.data.result[0], this.row)
            this.showSuccessMessage('Domain updated')
            this.close()
            this.domain = null
          } else {
            this.showErrorMessage(response.data.messages[0] || 'Failed to update domain')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.showErrorMessage(error.response.data.messages[0] || 'Failed to update domain')
        })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
