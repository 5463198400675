<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-authors` }"
            data-tooltip="Back to authors"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ $capitalize(author?.name) }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(author?.updated_at) }}</span>
          <span class="badge bg-azure ml-2">{{ author?.site.name }}</span>
          <span class="badge bg-purple ml-2" v-if="author?.market">{{ author?.market.label }}</span>
          <span class="badge bg-green ml-2" v-if="author?.active">active</span>
          <span class="badge bg-black-25 ml-2" v-if="!author?.active">inactive</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-authors/${author?.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-authors/${author?.id}/pages` }" exact>
        <a>Author Pages</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-authors/${author?.id}/reviewer-pages` }" exact>
        <a>Reviewer Pages</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import AuthorsService from '@services/AuthorsService'

export default {
  async created() {
    if (this.isIdMismatch) {
      await this.getAuthor()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
  },
  computed: {
    author() {
      return { ...this.$store.state.editPage.model }
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.author?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getAuthor() {
      await AuthorsService.getOne(this.$route.params['id'])
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Author not found') >= 0) {
                this.$router.push('/site-authors')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            window.siteUrl = response.data.result[0] && response.data.result[0].site.url
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
