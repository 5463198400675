export default {
  getForm(operatorId) {
    return [
      {
        name: 'type',
        search_url: `hercules/operators/game-types?operator_id=${operatorId}`,
        required: true,
        display_name: 'Type',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'type',
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        selectLabel: 'label',
        type: 'list-one',
        value_key: 'market.label',
        country_flag: 'country_code',
      },
      {
        name: 'target_operator_id',
        search_url: 'hercules/operators/operators',
        required: false,
        display_name: 'Target Operator',
        selectLabel: 'name',
        type: 'list-one',
        searchBy: 'name',
        fetch_on_load: false,
      },
    ]
  },
  getConfig() {
    return {
      name: 'Market Data',
      url: 'operators/bonuses',
    }
  },
  getStructure(operatorId) {
    return {
      list: this.getForm(operatorId),
      config: this.getConfig(),
    }
  },
}
